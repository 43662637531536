import React from 'react'
import Navbar from '../../Components/Navbar'
import { Link } from 'react-router-dom'
import Footer from '../../Components/Footer'
const MobileApplication = () => {
  return (
    <div>
      <Navbar/>
      <div>
        <div className='mobile'>
          <div className='container System text-white pt-5 wow def_ani animate__animated animate__fadeInRight'>
            <h1 className='container System text-white'>MobileApplication</h1>
            <Link className='container System' to='/'>
              <span class="text-white">Home</span></Link>
            <span>/</span>
            <a className='container System'>
              <span class="text-white">Products</span></a>
            <span>/</span>
            <a className='container System'>
              <span class="text-white">MobileApplication</span></a>
          </div>
        </div>

        <div className='container'>
        <h2 className='contentvk pb-4 wow def_ani animate__animated animate__fadeInUp'>Individual Installation Solutions</h2>
        <p className='industry-desc wow def_ani animate__animated animate__fadeIn'>Dried up field where agricultural is raising dust. Icy roads,which are cleared by getting vehicle.Drenched forest floors,where loggers struggle with mud and dirt.These everyday working conditions call for high quality and druble electrical installation solutions. </p>
      </div>


       <div className='container-fluid pb-5 wow def_ani animate__animated animate__fadeInLeft'>
       <div className='container pb100 pt100'>
            <div className='row'>
            <div className='col-12 col-sm-12 col-md-12 col-lg-6'>
                <img className=' automotivebackground_image' src='/Images/MobileappImage/111.png' alt='' />
              </div>
              <div className='col-12 col-sm-12 col-md-12 col-lg-6 contentvk'>
                <div className='contentvk'>
                  <h2 className='pb-3'>Maximized flexibility</h2>
                  <p className=''>The CANopen xtremeDB fieldbus block offers eight ports for inputs and outputs.The signal types digital, analog, PWM / PWM(I), ratiometric, counter/frequency can be configured individually. Thanks to the consistently used DTconnection technology, xtremeDB can be used with pre-assembled cordsets and connection cables, or perfectly integrated into single wire cable harnesses. The fieldbus modules from Data Panel and the pre-assembled cables from Murrelektronik have proven to be robust, tight and reliable in practic.</p>
                </div>
                </div>
                
              </div>
            </div>
       </div>

     
        <div className='container-fluid pt-5 pb-5 disc container-fluid_industry-full wow def_ani animate__animated animate__fadeInRight'>
       <div className='container pb100 pt100'>
            <div className='row'>
           
              <div className='col-12 col-sm-12 col-md-12 col-lg-6 txtwht contentvk'>
                <div className='contentvk'>
                  <h2 className='pb-3'>Pressure washer resistant</h2>
                  <p className=''>Pre-tested connecting cables not only save time during installation and troubleshooting, but also ensure that mobile machines are fail-safe and durable even under extreme conditions thanks to their robustness and impermeability. SNIS professional connection cables are fully potted or overmolded and are permanently resistant to  media and pressure washing. All connection cables are designed for compatibility and tightness with our xtremeDB passive and active distribution blocks. Mobile Automation: „Plugged in – done!</p>
                </div>
                </div>
                <div className='col-12 col-sm-12 col-md-12 col-lg-6'>
                <img className=' automotivebackground_image' src='/Images/MobileappImage/112.png' alt='' />
              </div>
              </div>
            </div>
       </div>


       <div className='container-fluid pt-5 pb-5 wow def_ani animate__animated animate__fadeInLeft'>
       <div className='container pb100 pt100'>
            <div className='row'>
            <div className='col-12 col-sm-12 col-md-12 col-lg-6'>
                <img className=' automotivebackground_image' src='/Images/MobileappImage/113.png' alt='' />
              </div>
              <div className='col-12 col-sm-12 col-md-12 col-lg-6 contentvk'>
                <div className='contentvk'>
                  <h2 className='pb-3'>Rational Installation</h2>
                  <p className=''>The market segment of mobile machines is still in its infancy, when it comes to the topic of “rational installation technology”. Cable harnesses and terminal boxes are state of the art in many applications. Due to the increasing technological development and variance of the machines, the lived concept “cable harness” reaches its limits, especially for small and medium production lots. With the xtremeDB passive splitters for signal distribution, including the appropriate cables, a modular control concept can be implemented quickly and easily in mobile machines. Time-consuming troubleshooting can thus be reduced to a minimum and the machine can be easily expanded at any time.</p>
                </div>
                </div>
                
              </div>
            </div>
       </div>

      </div>
<Footer/>
    </div>
  )
}

export default MobileApplication