import React from 'react'
import Navbar from '../../Components/Navbar'
import { Link } from "react-router-dom";
import Footer from '../../Components/Footer';


const Automotive = () => {
  return (
    <div>
      <Navbar />
      <div className='automative'>
        <div className='container System text-white pt-5 wow def_ani animate__animated animate__fadeInRight'>
          <h1 className='container System text-white'>Automotive</h1>
          <Link className='container System' to='/'>
            <span class="text-white">Home</span></Link>
          <span>/</span>
          <a className='container System'>
            <span class="text-white">Products</span></a>
          <span>/</span>
          <a className='container System'>
            <span class="text-white">Automotive</span></a>
        </div>
      </div>




      <div className='container'>
        <h2 className='contentvk pb-4 wow def_ani_1 animate__animated animate__fadeInDown'>SNIS is your distributor partner in the automotive industry</h2>
        <p className='industry-desc wow def_ani_1 animate__animated animate__fadeInDown'>From the press, body and paint shops, to aggregate production to the final assembly – all of the processes in an automotive factory must work well together and they must to be reliable so expensive downtimes can be avoided.The material handling industry has an important cross-divisional function in the automotive industry. Electric monorail or automated guided-vehicle systems move parts between the different areas while, at the same time, serving as component storage between those processes. Optimal run time is key, therefore, diagnostic options are a top priority for these systems.</p>
      </div>


      <div className='container-fluid'>
      <div className='container pb100 pt100'>
            <div className='row'>
              <div className='col-12 col-sm-12 col-md-12 col-lg-6 pr-5 '>
                <img className='automotivebackground_image2 wow def_ani animate__animated animate__fadeIn' src='/Images/AutmotiveImage/Automotive.png' alt='' />
              </div>
              <div className='col-12 col-sm-12 col-md-12 col-lg-6 contentvk'>
                <div className='contentvk pr-5'>
                  <h2 className='pb-3 wow def_ani animate__animated animate__fadeInRight'>New Energy Vehicles</h2>
                  <h4 className='pb-2 wow def_ani animate__animated animate__fadeIn'>Shaping tomorrow’s technologies today</h4>
                  <p className=' muli wow def_ani animate__animated animate__fadeInUp'>The production of vehicles that run on electric batteries or fuel cells poses major challenges not only for car manufacturers but also for the manufacturers of the associated charging infrastructure. Regardless of whether in body shop, battery assembly or in the development of hydrogen electrolysers – automation and industrialization solutions from Murrelektronik make production processes more economical.</p>
                </div>
              </div>
            </div>
          </div>
      </div>
         
       
       <div className='container-fluid pt-5 pb-5 disc txtwht ' style={{background:'#6d6d6d'}}>
       <div className='container pb100 pt100'>
            <div className="row d-flex flex-row flex-reverse">
            <div className='col-12 col-sm-12 col-md-12 col-lg-6'>
                <img className=' automotivebackground_image muli wow def_ani animate__animated animate__fadeIn' src='/Images/AutmotiveImage/52.jpg' alt='' />
              </div>
              <div className='col-12 col-sm-12 col-md-12 col-lg-6 contentvk'>
                <div className='contentvk'>
                  <h2 className='pb-3  wow def_ani animate__animated animate__fadeInRight'>Paint Shop & Assembly</h2>
                  <h4 className='pb-2 wow def_ani animate__animated animate__fadeIn'>The multi-stage process of painting</h4>
                  <p className='muli wow def_ani animate__animated animate__fadeInUp'>Painting is a multi-stage process that has to be carefully organized because of the need for different paint colors for each vehicle. High system reliability is necessary for avoiding downtime. Modular installation solutions for the final assembly equipment are a must because they are easy to handle and because the vehicle components are constantly changing.</p>
                </div>
                </div>
              </div>
            </div>
       </div>
    


      <div className='container-fluid'>
      <div className='container pb100 pt100'>
            <div className='row jcontent dflex'>
              <div className='col-12 col-sm-12 col-md-12 col-lg-6'>
                <img className='automotivebackground_image muli wow def_ani animate__animated animate__fadeIn' src='/Images/AutmotiveImage/53.jpeg' alt='' />
              </div>
              <div className='col-12 col-sm-12 col-md-12 col-lg-6 contentvk'>
                <div className='contentvk'>
                  <h2 className='pb-3  wow def_ani animate__animated animate__fadeInRight'>Powertrain</h2>
                  <h4 className='pb-2 wow def_ani animate__animated animate__fadeIn'>Shaping tomorrow’s technologies today</h4>
                  <p className='muli wow def_ani animate__animated animate__fadeInUp'>The production of vehicles that run on electric batteries or fuel cells poses major challenges not only for car manufacturers but also for the manufacturers of the associated charging infrastructure. Regardless of whether in body shop, battery assembly or in the development of hydrogen electrolysers – automation and industrialization solutions from Murrelektronik make production processes more economical.</p>               
                   </div>
              </div>
            </div>
          </div>
      </div>
<Footer/>
    </div>
  )
}

export default Automotive