import React, { useState } from 'react'
import { IData } from '../Data/InterfacesData';
import Navbar from '../../Components/Navbar'
import { Link } from "react-router-dom";
import Footer from '../../Components/Footer';


const Interface = () => {
  const[stores,setStored]=useState([IData]);
  // console.log(IData)

  return (
    <div >
      <Navbar/>
      <div className=' Interfacebackground text-white'>
        <div className='container System pt-5 mt-3  wow def_ani animate__animated animate__fadeInRight'>
        <h1 className='container System text-white '>Interface</h1>
          <Link className='container System' to='/'>
            <span class="text-white">Home</span></Link>
          <span class="text-white">/</span>
          <a className='container System'>
            <span class="text-white">product</span></a>
          <span class="text-white">/</span>
          <a className='container System'>
            <span class="text-white">Interface</span></a>
        </div>
      </div>

      <div className='container' style={{marginTop:'20px'}}>
        <div className="row vadjust">
          {
            IData.map((val) => {
              return (
                <div className='s_box cardSize'>
                  <div className="col-lg-4 col-sm-12 col-md-4">
                    <div className='card-image-size' >
                      <div className='imgage_height_system'>
                      <img src={val.image} className="container-img-top wow def_ani animate__animated animate__fadeIn" alt="container" />
                      </div>
                      <div className="container-sb" >
                        <h4 className="container-sh4 wow def_ani animate__animated animate__fadeInDown">{val.name}</h4>
                        <p className='sp wow def_ani_1 animate__animated animate__fadeIn'>{val.description}</p>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
        </div>
      </div>
     <Footer/>
    </div>
  )
}

export default Interface