import React from 'react'
import Navbar from '../../Components/Navbar'
import { Link } from 'react-router-dom'
import Footer from '../../Components/Footer'

const MachineTools = () => {
  return (
    <div>
      <Navbar/>

      <div>
        <div className='storage'>
          <div className='container System text-white pt-5 wow def_ani animate__animated animate__fadeInRight'>
            <h1 className='container System text-white'>MachineTools</h1>
            <Link className='container System' to='/'>
              <span class="text-white">Home</span></Link>
            <span>/</span>
            <a className='container System'>
              <span class="text-white">Products</span></a>
            <span>/</span>
            <a className='container System'>
              <span class="text-white">MachineTools</span></a>
          </div>
        </div>
        <div className='container'>
        <h2 className='industry-headline mt-5 mb-4 wow def_ani animate__animated animate__fadeInDown'>Confidence in Your Installation</h2>
        <p className='industry-desc mb-4 wow def_ani animate__animated animate__fadeIn'>Machine tooling is a wide-ranging industry. Customers needs and wishes are as diverse as the machines and plants themselves. But, there are a few common themes… cost-effectiveness, low overall costs, and increased competitiveness. Installations should link I/O points to the controller in an economical way. Murrelektronik has the experience to take on this challange. Our customers can select from a variety of passive and active installation concepts in a range of structures (ring, line or star topologies).</p>
      </div>

        <div className='container-fluid pb-5'>
       <div className='container pb100 pt100'>
            <div className='row'>
            
              <div className='col-12 col-sm-12 col-md-12 col-lg-6 contentvk'>
                <div className='contentvk'>
                  <h2 className='pb-3 wow def_ani animate__animated animate__fadeInDown'>Pressure washer resistant</h2>
                  <p className='wow def_ani animate__animated animate__fadeIn'>Pre-tested connecting cables not only save time during installation and troubleshooting, but also ensure that mobile machines are fail-safe and durable even under extreme conditions thanks to their robustness and impermeability. SNIS professional connection cables are fully potted or overmolded and are permanently resistant to  media and pressure washing. All connection cables are designed for compatibility and tightness with our xtremeDB passive and active distribution blocks. Mobile Automation: „Plugged in – done!</p>
                </div>
                </div>
                <div className='col-12 col-sm-12 col-md-12 col-lg-6'>
                <img className=' foodbeverage_image wow def_ani animate__animated animate__fadeInRight' src='/Images/MachineImage/21.png' alt='' />
              </div>
              </div>
            </div>
       </div>

       <div className='container-fluid pt-5 pb-5 disc container-fluid_industry-full txtwht'>
       <div className='container pb100 pt100'>
            <div className='row'>
            <div className='col-12 col-sm-12 col-md-12 col-lg-6'>
                <img className=' foodbeverage_image wow def_ani animate__animated animate__fadeInLeft' src='/Images/MachineImage/22.png' alt='' />
              </div>
              <div className='col-12 col-sm-12 col-md-12 col-lg-6 contentvk'>
                <div className='contentvk'>
                  <h2 className='pb-3wow def_ani animate__animated animate__fadeInDown'>Maximized flexibility</h2>
                  <p className='wow def_ani animate__animated animate__fadeIn'>The CANopen xtremeDB fieldbus block offers eight ports for inputs and outputs.The signal types digital, analog, PWM / PWM(I), ratiometric, counter/frequency can be configured individually. Thanks to the consistently used DTconnection technology, xtremeDB can be used with pre-assembled cordsets and connection cables, or perfectly integrated into single wire cable harnesses. The fieldbus modules from Data Panel and the pre-assembled cables from Murrelektronik have proven to be robust, tight and reliable in practic.</p>
                </div>
                </div>
               
              </div>
            </div>
       </div>

     
       <div className='container-fluid pb-5'>
       <div className='container pb100 pt100'>
            <div className='row'>
            
              <div className='col-12 col-sm-12 col-md-12 col-lg-6 contentvk'>
                <div className='contentvk'>
                  <h2 className='pb-3 wow def_ani animate__animated animate__fadeInDown'>Pressure washer resistant</h2>
                  <p className=' wow def_ani animate__animated animate__fadeIn'>Pre-tested connecting cables not only save time during installation and troubleshooting, but also ensure that mobile machines are fail-safe and durable even under extreme conditions thanks to their robustness and impermeability. SNIS professional connection cables are fully potted or overmolded and are permanently resistant to  media and pressure washing. All connection cables are designed for compatibility and tightness with our xtremeDB passive and active distribution blocks. Mobile Automation: „Plugged in – done!</p>
                </div>
                </div>
                <div className='col-12 col-sm-12 col-md-12 col-lg-6'>
                <img className=' foodbeverage_image wow def_ani animate__animated animate__fadeInRight' src='/Images/MachineImage/23.png' alt='' />
              </div>
              </div>
            </div>
       </div>

      </div>
      <Footer/>
    </div>
  )
}

export default MachineTools