import React from 'react'
import Navbar from '../../Components/Navbar'
import { Link } from "react-router-dom";
import Footer from '../../Components/Footer';

function Mosaic() {
  return (
    <>
    <Navbar/>
      <div className=' Interfacebackground text-white'>
        <div className='container System pt-5 mt-3  wow def_ani animate__animated animate__fadeInRight'>
        <h1 className='container System text-white'>Mosaic Panels</h1>
          <Link className='container System' to='/'>
            <span class="text-white">Home</span></Link>
          <span class="text-white">/</span>
          <a className='container System'>
            <span class="text-white">product</span></a>
          <span class="text-white">/</span>
          <a className='container System'>
            <span class="text-white">Control Room Line</span></a>
        </div>
      </div>

     
      <div className="container">
        <div className="row mb-5">

            <div className="col-12 col-md-4 col-lg-4">
                <div className="row">
                    <div className="col-12 mb-4">
                    <img src="./assets/images/prod/cr/mosaic/90-Series-Nylon-Grid-Mosaic-01.png" className="img_control_prod img-fluid container-img-top wow def_ani animate__animated animate__fadeIn" alt="" />
                    </div>
                    <div className="col-12">
                    <h4 className="container-sh4 wow def_ani animate__animated animate__fadeInDown">
                    90 Series Mosaic Mimic Control Panel

                    </h4>
                    <p className='sp wow def_ani_1 animate__animated animate__fadeIn'>
                    •	With over 30 years of experience in Mosaics. <br/>
•	Tiles are made of Plastic & consists high quality Poly Carbonate. <br/>
•	Werner has more than 200 installations in various control rooms. <br/>
•	Can supply entire console with wiring & integrating all equipment. <br/>


                    </p>
                    </div>
                </div>
            </div>
            
            <div className="col-12 col-md-4 col-lg-4">
                <div className="row">
                    <div className="col-12 mb-4">
                    <img src="./assets/images/prod/cr/mosaic/9000-Series-Metal-Grid-Mosaic-01.png" className="img_control_prod img-fluid container-img-top wow def_ani animate__animated animate__fadeIn" alt="" />
                    </div>
                    <div className="col-12">
                    <h4 className="container-sh4 wow def_ani animate__animated animate__fadeInDown">
                    9000 Series Metal Grid Mosaic

                    </h4>
                    <p className='sp wow def_ani_1 animate__animated animate__fadeIn'>
                    
• Metal grid made of die cast aluminum. <br/>
• Tiles are made of Plastic & consists high quality Poly Carbonate. <br/>
• Robust construction & high load bearing capacity. <br/>
• Available mosaic color similar to RAL 7032 / RAL 7035 / RAL 1015 <br/>


                    </p>
                    </div>
                </div>
            </div>

        </div>
    </div>





     <Footer/>


    </>
  )
}

export default Mosaic 