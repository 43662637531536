import React from 'react'
import Navbar from '../../Components/Navbar'
import { Link } from 'react-router-dom'
import Footer from '../../Components/Footer'

const Robotics = () => {
  return (
    <div>
      <Navbar/>
      <div className='Robotics'>
        <div className='container System text-white pt-5 wow def_ani animate__animated animate__fadeInRight'>
        <h1 className='container System text-white'>Robotics</h1>
          <Link className='container System' to='/'>
            <span class="text-white">Home</span></Link>
          <span>/</span>
          <a className='container System'>
            <span class="text-white">Products</span></a>
            <span>/</span>
          <a className='container System'>
            <span class="text-white">Robotics</span></a>
        </div>
      </div>



      <div className='container-fluid pb-5 wow def_ani animate__animated animate__fadeInLeft'>
       <div className='container pb100 pt100'>
            <div className='row'>
            <div className='col-12 col-sm-12 col-md-6 col-lg-6'>
                <img className=' foodbeverage_image' src='/Images/RoboticsImage/41.png' alt='' />
              </div>
              <div className='col-12 col-sm-12 col-md-6 col-lg-6 contentvk'>
                <div className='contentvk'>
                  <h2 className='pb-3'>Narrow Installation Space<br />at the Robot</h2>
                  <p className=''>The smallest bus modules from Murrelektronik even fit on axis 6 of a robot arm  this allows distributed installation up to the immediate proximity of the process.</p>
                </div>
                </div>
                
              </div>
            </div>
       </div>



       <div className='container-fluid pb-5 container-fluid_industry-full txtwht wow def_ani animate__animated animate__fadeInRight'>
       <div className='container pb100 pt100'>
            <div className='row'>
           
              <div className='col-12 col-sm-12 col-md-6 col-lg-6 contentvk'>
                <div className='contentvk'>
                  <h2 className='pb-3'>Vibration- and shock-resistant Components</h2>
                  <p className=''>The fieldbus modules from Murrelektronik are fully potted and are available either with a plastics or metal housing as desired. This makes them resistant to the high tightening and acceleration forces at the robot</p>
                </div>
                </div>
                <div className='col-12 col-sm-12 col-md-6 col-lg-6'>
                <img className=' foodbeverage_image' src='/Images/RoboticsImage/42.png' alt='' />
              </div>
              </div>
            </div>
       </div>

      <div className='container-fluid pb-5 wow def_ani animate__animated animate__fadeInLeft'>
       <div className='container pb100 pt100'>
            <div className='row'>
            <div className='col-12 col-sm-12 col-md-6 col-lg-6'>
                <img className=' foodbeverage_image' src='/Images/RoboticsImage/41.png' alt='' />
              </div>
              <div className='col-12 col-sm-12 col-md-6 col-lg-6 contentvk'>
                <div className='contentvk'>
                  <h2 className='pb-3'>Great Variety of torsion-resistant Cables</h2>
                  <p className=''>Murrelektronik has many years of experience with torsion-resistant cables suitable for drag chains and offers highly resistant cables with a large number of bending cycles and a high variance of cables and plug connectors.</p>
                </div>
                </div>
                
              </div>
            </div>
       </div>
<Footer/>
    </div>
  )
}

export default Robotics