import React from 'react'
import Footer from '../Components/Footer'
import Navbar from '../Components/Navbar'
const Download = () => {

  return (
    <div >
      <Navbar />
      <div className='catalogimage mb100 wow def_ani animate__animated animate__fadeInLeft'>
      </div>
      <div className='container-fluid blue mt-5 mb100'>
        <div class="row">
          <div class="col-12 col-sm-12 col-md-5 col-lg-4">
            <div className="pt-5 pb-3 mx-5 grey" >
              <img src="./Images/catalog/catalogbook.png" class="wow def_ani animate__animated animate__fadeIn"/>
            </div>
          </div>
          <div className="col-12 col-sm-12  col-md-7 col-lg-8 mt-5 industry-lists wow def_ani animate__animated animate__fadeInRight" >
            <h2>Murrelektronik General Catalog</h2><br />
            <p>PDF:19.MB</p>
            <h3><a className='downloadbutton' href='./assets/downloadpdf/Murrelektronik_General-catalog_EN.pdf'>Download</a></h3>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default Download