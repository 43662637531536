import React from 'react';
import Navbar from '../../Components/Navbar';
import { Link } from 'react-router-dom';
import Footer from '../../Components/Footer';

const Storage_Logisctic = () => {
  return (
    <div>
      <Navbar />
      <div>
        <div className='storage'>
          <div className='container System text-white pt-5 wow def_ani animate__animated animate__fadeInRight'>
            <h1 className='container System text-white'>Storage & Logistics</h1>
            <Link className='container System' to='/'>
              <span class="text-white">Home</span></Link>
            <span>/</span>
            <a className='container System'>
              <span class="text-white">Products</span></a>
            <span>/</span>
            <a className='container System'>
              <span class="text-white">Storage & Logistics</span></a>
          </div>
        </div>


        <div className='container-fluid pt-5 pb-5 disc '>
       <div className='container pb100 pt100'>
            <div className='row'>
            <div className='col-12 col-sm-12 col-md-12 col-lg-6'>
                <img className='wow def_ani animate__animated animate__fadeInLeft' src='/Images/StorageImage/33.png' alt='' />
              </div>
              <div className='col-12 col-sm-12 col-md-12 col-lg-6 contentvk'>
                <div className='contentvk'>
                  <h2 className='pb-3 wow def_ani animate__animated animate__fadeInDown'>Plug connectors – why use anything else?</h2>
                  <p className='wow def_ani animate__animated animate__fadeIn'>Our molded connectors and distribution boxes combined reduce installation time by up to 40% and ensure error-free, reliable connections.</p>
                </div>
                </div>
              </div>
            </div>
       </div>

       <div className='container-fluid pt-5 pb-5 disc container-fluid_industry-full txtwht'>
       <div className='container pb100 pt100'>
            <div className='row'>
            
              <div className='col-12 col-sm-12 col-md-12 col-lg-6 contentvk'>
                <div className='contentvk'>
                  <h2 className='pb-3 wow def_ani animate__animated animate__fadeInLeftDown'>Customized Solutions</h2>
                  <p className=' wow def_ani animate__animated animate__fadeIn'>Across the majority of our connector families, we have the flexibility to customize to meet your needs without compromising our high quality standards.</p>
                </div>
                </div>
                <div className='col-12 col-sm-12 col-md-12 col-lg-6'>
                <img className='wow def_ani animate__animated animate__fadeInRight' src='/Images/StorageImage/32.png' alt='' />
              </div>
              </div>
            </div>
       </div>

       <div className='container-fluid pt-5 pb-5 disc '>
       <div className='container pb100 pt100'>
            <div className='row'>
            <div className='col-12 col-sm-12 col-md-12 col-lg-6'>
                <img className='wow def_ani animate__animated animate__fadeInLeft ' src='/Images/StorageImage/31.png' alt='' />
              </div>
              <div className='col-12 col-sm-12 col-md-12 col-lg-6 contentvk'>
                <div className='contentvk'>
                  <h2 className='pb-3 wow def_ani animate__animated animate__fadeInDown'>Extreme Conditions</h2>
                  <p className=' wow def_ani animate__animated animate__fadeIn'>Murrelektronik’s passive distribution boxes, fieldbus modules and valve plugs are fully potted enabling them to withstand both high temperatures strong vibrations.</p>
                </div>
                </div>
               
              </div>
            </div>
       </div>

      </div>

<Footer/>

    </div>
    // </div>
  )
}

export default Storage_Logisctic