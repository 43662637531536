import React from 'react'
import { Link } from "react-router-dom"

const Navbar = () => {
  return (
    <div>
     
    <nav class="navbar sticky-top navbar-expand-lg navbar-light bg_white nav_height fixed_nav">
        <div class="container">
          <Link class="navbar-brand" to="/"><img src="./assets/images/SNIS_logo.png" class="nav_logo_desk" to="/" /></Link>
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse row" id="navbarNavDropdown">
            <ul class="navbar-nav col-12 d-flex align-items-center justify-content-end">
              <li class="nav-item pe-2">
                <Link class="nav-link zoom" aria-current="page" to="/">
                    <span class="px16 roboto home_nav "><strong>Home</strong></span>
                </Link> 
              </li>
                <li class="nav-item pe-2">
                <a class="nav-link zoom" href="/aboutUs">
                    <span class="px16 roboto home_nav "><strong>About Us</strong></span>
                </a>
              </li>
              <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle px16 roboto home_nav border-0 extrabold" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Product
                </a>
              <div class="dropdown-menu">
              <ul>
                  <li><a class="dropdown-item toblue" href="#">Our Products</a></li>
                  <br />
                  <li><Link class="dropdown-item col-12 col-md-12 col-lg-3 d-flex justify-content-center justify-content-lg-start align-items-center text_foot_blue  zoom" to="/connection_Technology">Connection Technology</Link></li>
                  <li><Link class="dropdown-item col-12 col-md-12 col-lg-3 d-flex justify-content-center justify-content-lg-start align-items-center text_foot_blue  zoom" to="/interface">Interface</Link></li>
                  <li><Link class="dropdown-item col-12 col-md-12 col-lg-3 d-flex justify-content-center justify-content-lg-start align-items-center text_foot_blue  zoom" to="/system">Input Outpt System I/O</Link></li>
                  <li><Link class="dropdown-item col-12 col-md-12 col-lg-3 d-flex justify-content-center justify-content-lg-start align-items-center  text_foot_blue  zoom" to="/panel_Cabinet">Panel Cabinet</Link></li> 

                  <li><a class="dropdown-item toblue" href="#">Control Components</a></li>
                  <li><Link class="dropdown-item col-12 col-md-12 col-lg-3 d-flex justify-content-center justify-content-lg-start align-items-center  text_foot_blue  zoom" to="/switches">Switches and Pilot Lights</Link></li>
                  <li><Link class="dropdown-item col-12 col-md-12 col-lg-3 d-flex justify-content-center justify-content-lg-start align-items-center  text_foot_blue  zoom" to="/relays">Relays and socket</Link></li>
                  <li><Link class="dropdown-item col-12 col-md-12 col-lg-3 d-flex justify-content-center justify-content-lg-start align-items-center  text_foot_blue  zoom" to="/remotecontrol">Remote Control Pendent</Link></li>
                  <li><Link class="dropdown-item col-12 col-md-12 col-lg-3 d-flex justify-content-center justify-content-lg-start align-items-center  text_foot_blue  zoom" to="/annunciation">Annunciation Windows</Link></li>
                  <li><Link class="dropdown-item col-12 col-md-12 col-lg-3 d-flex justify-content-center justify-content-lg-start align-items-center  text_foot_blue  zoom" to="/signaltower">Signal Tower</Link></li>
                  
                  <br/>
                  <li className="mt-3 toblue"><a class="dropdown-item toblue" href="#">Control Room Line</a></li>
                  <li><Link class="dropdown-item col-12 col-md-12 col-lg-3 d-flex justify-content-center justify-content-lg-start align-items-center text_foot_blue  zoom" to="/Mosaic">Mosaic Panels & Accessories</Link></li>
                  <li><Link class="dropdown-item col-12 col-md-12 col-lg-3 d-flex justify-content-center justify-content-lg-start align-items-center text_foot_blue  zoom" to="/OperatorWorkstation">Operator Workstations & Consoles</Link></li>
                
              </ul>
                <ul>
                 
                </ul>
            
                <ul>
         
                </ul>
            
                <ul>
                  <li><a class="dropdown-item toblue" href="#">Interface Products</a></li>
                  <li><Link class="dropdown-item col-12 col-md-12 col-lg-3 d-flex justify-content-center justify-content-lg-start align-items-center text_foot_blue  zoom" to="/terminalblocks">Terminal Blocks</Link></li>
                  <li><Link class="dropdown-item col-12 col-md-12 col-lg-3 d-flex justify-content-center justify-content-lg-start align-items-center text_foot_blue  zoom" to="/relayinterface">Relay Interface Modules</Link></li>
                  <br/>
                  <li><a class="dropdown-item toblue" href="#">Other Products</a></li>
                  <li><Link class="dropdown-item col-12 col-md-12 col-lg-3 d-flex justify-content-center justify-content-lg-start align-items-center text_foot_blue  zoom" to="/dinrail">Din rail</Link></li>
                  <li><Link class="dropdown-item col-12 col-md-12 col-lg-3 d-flex justify-content-center justify-content-lg-start align-items-center text_foot_blue  zoom" to="/terminalenclosure">Terminal Enclosure</Link></li>
                  <li><Link class="dropdown-item col-12 col-md-12 col-lg-3 d-flex justify-content-center justify-content-lg-start align-items-center text_foot_blue  zoom" to="/FanFailureDetector">Fan Failure Detector</Link></li>

                </ul>
               
                <ul>
              
                </ul>
                
            </div>
               </li>

              <li class="nav-item dropdown pe-2 pe-md-0 text-center">
                <a class="nav-link dropdown-toggle zoom" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="" aria-expanded="false">
                    <span class="px16 roboto home_nav"><strong>Industry</strong></span>
                </a>
                <ul class="dropdown-menu row text-center border-0 justify-content-center align-items-center " aria-labelledby="navbarDropdownMenuLink">
                  <li><Link class="dropdown-item  col-12 col-md-12 col-lg-3 d-flex justify-content-center justify-content-lg-start align-items-center text_foot_blue zoom" to="/automotive">Automotive</Link></li>
                  <li><Link class="dropdown-item  col-12 col-md-12 col-lg-3 d-flex justify-content-center justify-content-lg-start align-items-center text_foot_blue  zoom" to="/food_beverage">Food &#38; Beverage</Link></li>
                  <li><Link class="dropdown-item  col-12 col-md-12 col-lg-3 d-flex justify-content-center justify-content-lg-start align-items-center text_foot_blue  zoom" to="/storage_logistics">Storage &#38; Logistics</Link></li>
                  <li><Link class="dropdown-item  col-12 col-md-12 col-lg-3 d-flex justify-content-center justify-content-lg-start align-items-center text_foot_blue  zoom" to="/mobileApplication">Mobile Applications</Link></li>
                  <li><Link class="dropdown-item  col-12 col-md-12 col-lg-3 d-flex justify-content-center justify-content-lg-start align-items-center text_foot_blue  zoom" to="/robotics">Robotics</Link></li>
                  <li><Link class="dropdown-item  col-12 col-md-12 col-lg-3 d-flex justify-content-center justify-content-lg-start align-items-center text_foot_blue  zoom" to="/machineTool">Machine Tools</Link></li>
                </ul>
              </li>
              <li class="nav-item pe-2">
                <Link class="nav-link zoom" to="/download">
                    <span class="px16 roboto home_nav"><strong>Download</strong></span>
                </Link>
              </li>
              <li class="nav-item pe-2">
                <Link class="nav-link zoom" to="/contactUs">
                    <span class="px16 roboto home_nav"><strong>Contact Us</strong></span>
                </Link>
              </li>
              <li class="nav-item pe-2">
              <Link class="nav-link " to="/contactUs">
              <button type="button" class="btn nav_quote_button btn-md">
                    <span class="text-white px16 muli">Get A Quote</span>
                   </button>
                   </Link>
              </li>
            </ul>
          
            
          </div>
        </div>
      </nav>

    </div>
  )
}

export default Navbar