import React from 'react'
import Navbar from '../../Components/Navbar'
import { Link } from 'react-router-dom'
import Footer from '../../Components/Footer'

const Food_Beverage = () => {
  return (
    <div>
      <Navbar/>

      <div className='food'>
        <div className='container System text-white pt-5  wow def_ani animate__animated animate__fadeInRight'>
        <h1 className='container System text-white'>Food&Beverage</h1>
          <Link className='container System' to='/'>
            <span class="text-white">Home</span></Link>
          <span>/</span>
          <a className='container System'>
            <span class="text-white">Products</span></a>
            <span>/</span>
          <a className='container System'>
            <span class="text-white">Food&Beverage</span></a>
        </div>
      </div>


      <div className='container'>
        <h2 className='contentvk wow def_ani animate__animated animate__fadeInUp'>Food and Beverage Industry</h2>
        <p className='contentvk pt-2 pb-5 wow def_ani animate__animated animate__fadeInUp'>This industry places extreme demands on all of its facilities and systems from the processing itself down to the packaging and palletizing.Processes and products in the food and beverage industry must meet the requirements governing hygiene, cleanliness and corrosion protection. Being able to  easliy and completely remove dirt, or other contaminates, is the main requirement for these components. This requirement applies to all three major machine zones.</p>
      </div>

      <div className='container-fluid gr'>
      <div className='container pt100 pb100'>
            <div className='row'>
              <div className='col-12 col-sm-12 col-md-12 col-lg-6 imagevk'>
                <img className='foodbeverage_image  wow def_ani animate__animated animate__fadeInLeft' src='/Images/FoodImages/56.png' alt='' />
              </div>
              <div className='col-12 col-sm-12 col-md-12 col-lg-6 contentvk'>
                <div className='contentvk'>
                  <h2 className='industry-h2 wow def_ani animate__animated animate__fadeInRight'>Food Contact Zone</h2>
                  <h4 className='pb-2 wow def_ani animate__animated animate__fadeInRight'>Components Include</h4>
                  <ul className='list-type pt-2 imagevksize2 disc wow def_ani animate__animated animate__fadeInRight'>
                    <li className='industry-list contentvk wow def_ani animate__animated animate__fadeInRight'>Stainless Steel</li><br/>
                    <li className='contentvk wow def_ani animate__animated animate__fadeInRight'>IP69K Rating</li><br/>
                    <li className='contentvk wow def_ani animate__animated animate__fadeInRight'>TPE-S Cable</li><br/>
                    <li className='contentvk wow def_ani animate__animated animate__fadeInRight'>Food Grade Quality</li><br/>
                    <li className='contentvk wow def_ani animate__animated animate__fadeInRight'>Acid and Alkaline Solution Resistant</li><br/>
                    <li className='contentvk wow def_ani animate__animated animate__fadeInRight'>H2O2 (Hydrogen peroxide) Resistant</li><br/>
                    <li className='contentvk wow def_ani animate__animated animate__fadeInRight'>FDA Compliant</li><br/>
                  </ul>
              </div>
                </div>
            </div>
          </div>
      </div>

<div className='container-fluid container-fluid_industry-full txtwht'>
<div className='container '>
      <div className='container-fluid pt100 pb100'>
            <div className='row'>
              <div className='col-12 col-sm-12 col-md-12 col-lg-6 imagevk'>
                <div className='contentvk'>
                  <h2 className='industry-h2 pb-3 wow def_ani animate__animated animate__fadeInUp'>Food Contact Zone</h2>
                  <h4 className='pb-2 wow def_ani animate__animated animate__fadeIn'>Components Include</h4>
                  <ul className='list-type pt-2 imagevksize2 disc'>
                    <li className='wow def_ani animate__animated animate__fadeInLeft'>Increase System Availability</li><br/>
                    <li className='wow def_ani animate__animated animate__fadeInLeft'>Provide Comprehensive Diagnostics</li><br/>
                    <li className='wow def_ani animate__animated animate__fadeInLeft'>Offer Economic Decentralization Ideas</li><br/>
                    <li className='wow def_ani animate__animated animate__fadeInLeft'>Suggest Products Suitable for CIP Cleaning Processes</li><br/>
                  </ul>
                </div>
              </div>
              <div className='col-12 col-sm-12 col-md-12 col-lg-6'>
                <img className=' foodbeverage_image wow def_ani animate__animated animate__fadeInRightwow def_ani animate__animated animate__fadeInRight' src='/Images/FoodImages/57.png' alt='' />
              </div>
            </div>
          </div>
      </div>

</div>
    <div className='container-fluid gr'>
    <div className='container gr'>
      <div className='container-fluid pt100 pb100'>
            <div className='row'>
              <div className='col-12 col-sm-12 col-md-12 col-lg-6 imagevk '>
                <img className='foodbeverage_image wow def_ani animate__animated animate__fadeInLeft' src='/Images/FoodImages/58.png' alt='' />
              </div>
              <div className='col-12 col-sm-12 col-md-12 col-lg-6'>
                <div className='contentvk'>
                  <h2 className='industry-h2 pb-3 wow def_ani animate__animated animate__fadeInDown'>Dry Zone</h2>
                  <h4 className='pb-2 wow def_ani animate__animated animate__fadeIn'>SNIS Offers</h4>
                  <ul className='disc'>
                    <li className='wow def_ani animate__animated animate__fadeInRight'>Quick and Easy Installation</li><br/>
                    <li className='wow def_ani animate__animated animate__fadeInRight'>Modular Construction</li><br/>
                    <li className='wow def_ani animate__animated animate__fadeInRight'>Easy to Clean Solutions</li><br/>
                    <li className='wow def_ani animate__animated animate__fadeInRight'>C-Track Rated Components</li><br/>
                  </ul>
              </div>
                </div>
            </div>
          </div>
      </div>
    </div>
      <Footer/>
    </div>

  )
}

export default Food_Beverage