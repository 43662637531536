import React from 'react'
import Navbar from '../../Components/Navbar'
import { Link } from "react-router-dom";
import Footer from '../../Components/Footer';

function TerminalBlocks() {
  return (
    <>
    <Navbar/>
      <div className=' Interfacebackground text-white'>
      <div className='container System pt-5 mt-3  wow def_ani animate__animated animate__fadeInRight'>
        <h1 className='container System text-white'>Terminal Blocks</h1>
          <Link className='container System' to='/'>
            <span class="text-white">Home</span></Link>
          <span class="text-white">/</span>
          <a className='container System'>
            <span class="text-white">product</span></a>
          <span class="text-white">/</span>
          <a className='container System'>
            <span class="text-white">Interface Products</span></a>
        </div>
      </div>

      <div className="container">
        <div className="row mb-5">

            <div className="col-12 col-md-4 col-lg-4">
                <div className="row">
                    <div className="col-12 mb-4">
                    <img src="./assets/images/prod/ip/teminal/77-Series-DIN-Rail-Terminal-Blocks.png" className="img_control_prod img-fluid container-img-top wow def_ani animate__animated animate__fadeIn" alt="" />
                    </div>
                    <div className="col-12">
                    <h4 className="container-sh4 wow def_ani animate__animated animate__fadeInDown">

                    77 Series DIN Rail Terminal Blocks
                    </h4>
                    <p className='sp wow def_ani_1 animate__animated animate__fadeIn'>
                    •	One piece construction concept. <br/>
•	Standard 35 mm DIN rail mounting. <br/>
•	Terminal blocks are flame & shock resistant. <br/>
•	Many options of terminations available in this model. <br/>
•	Terminal blocks are with marking strips and dust covers. <br/>
•	Terminal blocks with fuse & indicator (LED/Neon)available. <br/>
•	Spring lift block with Touch-down terminals reduces down time. <br/>
•	Spring lift block also equipped with Spring-loaded captive screws. <br/>


                    </p>
                    </div>
                </div>
            </div>
            
            <div className="col-12 col-md-4 col-lg-4">
                <div className="row">
                    <div className="col-12 mb-4">
                    <img src="./assets/images/prod/ip/teminal/78-Series-DIN-Rail-Terminal-Blocks-1.png" className="img_control_prod img-fluid container-img-top wow def_ani animate__animated animate__fadeIn" alt="" />
                    </div>
                    <div className="col-12">
                    <h4 className="container-sh4 wow def_ani animate__animated animate__fadeInDown">

                    78 Series DIN Rail Terminal Blocks
                    </h4>
                    <p className='sp wow def_ani_1 animate__animated animate__fadeIn'>
           
                    •	Mount on standard 35 mm Din rail. <br/>
•	Terminal blocks are explosive resistant. <br/>
•	Shock resistant, easy mounting & dismounting. <br/>
•	Sleeves & shorting links screws for cross connection available. <br/>
•	Marker holding to accept marking tags for circuit identification. <br/>
•	Standard industrial color coding of green / yellow in this range. <br/>
•	Terminal blocks suitable for grounding / earthing wires available. <br/>
•	Ideal block to terminate control wiring of PLC’s, contactors & relays. <br/>

                    </p>
                    </div>
                </div>
            </div>
            <div className="col-12 col-md-4 col-lg-4">
                <div className="row">
                    <div className="col-12 mb-4">
                    <img src="./assets/images/prod/ip/teminal/770-Series-Spring-Up-Screw-Terminal-Blocks.png" className="img_control_prod img-fluid container-img-top wow def_ani animate__animated animate__fadeIn" alt="" />
                    </div>
                    <div className="col-12">
                    <h4 className="container-sh4 wow def_ani animate__animated animate__fadeInDown">
                    770 Series Spring-Up Screw Terminal Blocks

                    </h4>
                    <p className='sp wow def_ani_1 animate__animated animate__fadeIn'>
                    •	One piece construction concept. <br/>
•	Standard 35 mm DIN rail mounting. <br/>
•	Terminal blocks are flame & shock resistant. <br/>
•	Many options of terminations available in this model. <br/>
•	Terminal blocks are with marking strips and dust covers. <br/>
•	Spring lift block with Touch-down terminals reduces down time. <br/>
•	Spring lift block also equipped with Spring-loaded captive screws. <br/>


                    </p>
                    </div>
                </div>
            </div>

        </div>
    </div>



     <Footer/>


    </>
  )
}

export default TerminalBlocks 