export const CData=[
    {
        id:1,
        image:'Images/ConnectionTechnologyImage/11.jpg',
        name:'Adapters',
        description:'Adapters provide unique connectivity options and are beneficial in providing creative solutions to everyday problems. They provide additional flexibility during the installation process without the need for custom cable assemblies.'
    },
    {
        id:2,
        image:'Images/ConnectionTechnologyImage/12.jpg',
        name:'Cables by the Meter',
        description:'Murrelektronik offers the same high quality and variety of cables used in our cordsets in the convenience of a 100 or 500m spool. This service allows our customers to purchase the lengths they need, when they need them.'
    },
    {
        id:3,
        image:'Images/ConnectionTechnologyImage/18.jpg',
        name:'Field-wireable connectors',
        description:'Cable termination is easier than ever with the unique designs from Murrelektronik. Whether you choose Insualtion Dispalcement Connectors or screw terminal terminations our products help to reduce your wiring efforts.'
    },
    {
        id:4,
        image:'Images/ConnectionTechnologyImage/13.jpg',
        name:'Fieldbus Cables',
        description:'Murrelektronik offers a variety of cables designed to support industrial protocols including AS-I, Can Open, Device Net, Ethernet/IP, Ether CAT, ProfiBus, ProfiNet, Sercos and many more. '
    },
    {
        id:5,
        image:'Images/ConnectionTechnologyImage/14.jpg',
        name:'Motor cables',
        description:'Servo motors are connected with compact M23 connectors. These connectors have become an established standard instead of the previously used terminal boxes. Murrelektronik offers power cables size 1.0 and signal cables in molded versions.'
    },
    {
        id:6,
        image:'Images/ConnectionTechnologyImage/16.jpg',
        name:'Sensor Actuator Cables',
        description:'Murrelektronik offers one of the largest cordset lineups on the market for connecting sensors and valves. Many of these are in stock or they can be produced within 48 hours of receipt of your order – even if you just need one piece.'
    },
    {
        id:7,
        image:'Images/ConnectionTechnologyImage/17.jpg',
        name:'T-Couplers',
        description:'The T-Coupler doubles connection capacity by providing two connectors and simplifies installation with fixed coupling nuts. By increasing the connection capacity, the T-Coupler dramatically reduces the number of fieldbus I/O or distribution boxes needed by as much as 50%.'
    },
    {
        id:8,
        image:'Images/ConnectionTechnologyImage/15.jpg',
        name:'Torque Wrench',
        description:'Murrelektronik’s unique torque wrench ensures connectors are tightened to precisely the right degree. Turn the wrench until it clicks and guarantee long-term, consistent connection quality with IP67 protection. The torque wrench is available in 6 sizes: M8, M8 Food & Bev., M12, M12 Food & Bev., M23 and 7/8 and works with all Murrelektronik connectors of the same size.'
    }
]