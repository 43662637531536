import React from 'react'
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div>


    {/* <!-- footer part --> */}
      <footer class="container-fluid bg_footer_sky_blue  footer_padding text-white">
          <div class="container-sm px-0 px-sm-5">
              <div class="row px-3">
                  <div class="col-12 col-sm-12 col-lg-4 text_left">
                      <img src="./assets/images/SNIS_logo.png" class="footer_logo mb-3" alt="" />
                      <div class="my-3">
                          <p class="px14 muli text_left">
                              <i class="fa-solid fa-earth-americas pe-2"></i> NO 85/1 C BLOCK R H C S LAYOUT <br></br>SRIGANDADAKAVAL BANGALORE 560091
                          </p>
                      </div>
                      <div class="my-3">
                          <a href="tel:+919845373148" class="a_tag_no_dec black_hover">
                              <p class="px14 muli my-2"><i class="fa-solid fa-phone pe-2"></i> +919845373148</p>
                            </a>
                      </div>
                      <div class="my-3">
                          <a href="mailto:info@snisindia.in" class="a_tag_no_dec black_hover">
                              <p class="px14 muli my-2"><i class="fa-solid fa-envelope pe-2"></i> info@snisindia.in</p>
                          </a>
                      </div>
                      <div class="social_media my-3">
                        <ul class="p-0 m-0">
                            <li class="zoom social_icons_hover"><a href='#'><i class="fa-brands wp-icon fa-facebook-f fa-x"></i></a></li>
                            <li class="zoom social_icons_hover"><a href='#'><i class="fa-brands wp-icon fa-twitter fa-x"></i></a></li>
                            <li class="zoom social_icons_hover"><a href='#'><i class="fa-brands wp-icon fa-pinterest fa-x"></i></a></li>
                            <li class="zoom social_icons_hover"><a href='#'><i class="fa-brands wp-icon fa-linkedin fa-x"></i></a></li>
                           
                            </ul>
                      </div>  

                  </div>
                  <div class="col-12 col-sm-12 col-lg-5 text_left">
                      <h2 class="px22 poppins mb-5 pt-4 pt-lg-0 under_effect">Company</h2>
                      <Link to="/" class="px14 muli a_tag_no_dec my-1  underline_foot black_hover"><p>Home</p></Link>
                      <Link to="/aboutUs" class="px14 muli a_tag_no_dec my-1 underline_foot black_hover"><p>About Us</p></Link>
                      <Link to="/panel_Cabinet" class="px14 muli a_tag_no_dec my-1 underline_foot black_hover"><p>Product</p></Link>
                      <Link to="/contactUs" class="px14 muli a_tag_no_dec my-1 underline_foot black_hover"><p>Contact Us</p></Link>
                  </div>
                  <div class="col-12 col-sm-12 col-lg-3 d-flex flex-column align-items-start">
                      <h2 class="px22 poppins mb-5 pt-4 pt-lg-0 under_effect text_left">Opening Hours</h2>
                      <p class="px14 muli text_left"> <strong>Mon- Fri: 9:30 am - 6.00 pm</strong></p>
                      <p class="px14 muli text_left"> <strong>Saturday: 9:30 am - 2.30 pm</strong></p>
                      <p class="px14 muli text_left"> <strong>Sunday:closed</strong></p>
                  </div>
              </div>
          </div>
      </footer>



    {/* <!-- ALL RIGHTS RESERVED PART --> */}
      <footer class="container-fluid bg_footer_sky_blue footer_height_rights text-white d-flex justify-content-center align-items-center">
          <p class="">&#169; 2022 All Rights Reserved</p>

      </footer>
    </div>
  )
}

export default Footer