import React from 'react'
import Navbar from '../../Components/Navbar'
import { Link } from "react-router-dom";
import Footer from '../../Components/Footer';

function SwitchesAndLights() {
  return (
    <>
    <Navbar/>
      <div className=' Interfacebackground text-white'>
        <div className='container System pt-5 mt-3  wow def_ani animate__animated animate__fadeInRight'>
        <h1 className='container System text-white'>Switches And Lights</h1>
          <Link className='container System' to='/'>
            <span class="text-white">Home</span></Link>
          <span class="text-white">/</span>
          <a className='container System'>
            <span class="text-white">product</span></a>
          <span class="text-white">/</span>
          <a className='container System'>
            <span class="text-white">Interface</span></a>
        </div>
      </div>

      <div className='container mb-5' style={{marginTop:'20px'}}>
        <div className="row vadjust">

        <div className="s_box cardSize mt-5 mb-5">

            <div className="col-lg-4 col-sm-12 col-md-4">
                <div className='card-image-size'>
                    <div className='imgage_height_system'>
                        <img src="./assets/images/prod/cc/switches/32-series-jumbo-dome-pilot-light-4.png" className="img-fluid container-img-top wow def_ani animate__animated animate__fadeIn" alt="" />
                    </div>
                    <div className="container-sb">
                    <h4 className="container-sh4 wow def_ani animate__animated animate__fadeInDown">
                    32 Series Jumbo Dome Pilot  Lights
                    </h4>
                    <p className='sp wow def_ani_1 animate__animated animate__fadeIn'>
                    •	Mounting: ø22mm <br />
                    •	The new Jumbo Dome pilot light achieves high brightness. <br />
                    •	Short Depth behind the Panel <br />
                    •	Low Voltage Glow Protective <br />
                    •	ø66mm big dome lens <br />
                    •	Clear and easy recognition from a distance & from the side. <br />

                    </p>
                    </div>
                </div>
            </div>

        </div>
           
        <div className="s_box cardSize mt-5 mb-5">

            <div className="col-lg-4 col-sm-12 col-md-4">
                <div className='card-image-size'>
                    <div className='imgage_height_system'>
                        <img src="./assets/images/prod/cc/switches/40-series-switch-01.png" className="img-fluid container-img-top wow def_ani animate__animated animate__fadeIn" alt="" />
                    </div>
                    <div className="container-sb">
                    <h4 className="container-sh4 wow def_ani animate__animated animate__fadeInDown">
                    40 Series Switches & Pilot Devices
                    </h4>
                    <p className='sp wow def_ani_1 animate__animated animate__fadeIn'>
                    •	Mounting: 16mm <br />
•	Contacts: SPDT / DPDT <br />
•	IP40 & IP65 <br />
•	110″ quick connect / solder terminals <br />
•	Gold Plated Contacts and Gold Plated Terminals <br />
•	Range includes Illuminated E-stop, Illuminated Selector and Flush Illuminated Push Button <br />

                    </p>
                    </div>
                </div>
            </div>

        </div>

        <div className="s_box cardSize mt-5 mb-5">

        <div className="col-lg-4 col-sm-12 col-md-4">
            <div className='card-image-size'>
                <div className='imgage_height_system'>
                    <img src="./assets/images/prod/cc/switches/41-Series-Switches-Indicators.png" className="img-fluid container-img-top wow def_ani animate__animated animate__fadeIn" alt="" />
                </div>
                <div className="container-sb">
                <h4 className="container-sh4 wow def_ani animate__animated animate__fadeInDown">
                41 Series Switches & Pilot Devices
                </h4>
                <p className='sp wow def_ani_1 animate__animated animate__fadeIn'>
                •	Mounting: 16mm <br />
        •	Contacts: 1NO + 1NC ~ 5NO+5NC <br />
        •	Certified for Seismic zone 3 <br />
        •	IP40 & IP65 <br />
        •	Midget Base LED is used for illumination <br />
        •	Gold Plated Contacts and Gold Plated Terminals <br />
        •	Extended range includes Illuminated E-stop, Illuminated Selector, Illuminated Key Switch and 220VAC Pilot Light <br />

                </p>
                </div>
            </div>
        </div>

        </div>
        </div>

        <div className="row vadjust">

        <div className="s_box cardSize mt-5 mb-5">

            <div className="col-lg-4 col-sm-12 col-md-4">
                <div className='card-image-size'>
                    <div className='imgage_height_system'>
                        <img src="./assets/images/prod/cc/switches/44-Series-Switches-indicators.png" className="img-fluid container-img-top wow def_ani animate__animated animate__fadeIn" alt="" />
                    </div>
                    <div className="container-sb">
                    <h4 className="container-sh4 wow def_ani animate__animated animate__fadeInDown">
                    44 Series Switches & Pilot Devices
                    </h4>
                    <p className='sp wow def_ani_1 animate__animated animate__fadeIn'>
                    •	Mounting: 22 mm <br />
•	Contacts: NO, NC – Max. 6 Contacts. <br />
•	IP20 Back of Panel & IP65 Front of Panel <br />
•	Silver Copper Alloy used for contacts <br />
•	Unit body pilot lights <br />
•	Features Metal bezel <br />

                    </p>
                    </div>
                </div>
            </div>

        </div>

        <div className="s_box cardSize mt-5 mb-5">

            <div className="col-lg-4 col-sm-12 col-md-4">
                <div className='card-image-size'>
                    <div className='imgage_height_system'>
                        <img src="./assets/images/prod/cc/switches/45-series-Switches-and-Indicators.png" className="img-fluid container-img-top wow def_ani animate__animated animate__fadeIn" alt="" />
                    </div>
                    <div className="container-sb">
                    <h4 className="container-sh4 wow def_ani animate__animated animate__fadeInDown">
                    45 Series Switches & Pilot Devices
                    </h4>
                    <p className='sp wow def_ani_1 animate__animated animate__fadeIn'>
                    •	Mounting: 22 mm <br />
•	Contacts: 1NO, 1NC, 2NO, 2NC, 1NO+1NC – Max. 6 to 9 Contacts <br />
•	IP20 Back of Panel & IP65 Front of Panel <br />
•	Silver Copper Alloy used for contacts <br />
•	Modular type pilot lights with BA9S LED <br />
•	Low cost build designs <br />

                    </p>
                    </div>
                </div>
            </div>

        </div>

        <div className="s_box cardSize mt-5 mb-5">

            <div className="col-lg-4 col-sm-12 col-md-4">
                <div className='card-image-size'>
                    <div className='imgage_height_system'>
                        <img src="./assets/images/prod/cc/switches/45-metal-01-2.png" className="img-fluid container-img-top wow def_ani animate__animated animate__fadeIn" alt="" />
                    </div>
                    <div className="container-sb">
                    <h4 className="container-sh4 wow def_ani animate__animated animate__fadeInDown">
                    45 Series Switches & Pilot Devices
                    </h4>
                    <p className='sp wow def_ani_1 animate__animated animate__fadeIn'>
                    •	Mounting: 22 mm <br />
•	Contacts: 1NO, 1NC, 2NO, 2NC, 1NO+1NC – Max. 6 Contacts <br />
•	IP20 Back of Panel & IP65 Front of Panel <br />
•	Silver Copper Alloy used for contacts <br />
•	Features Metal coated bezel <br />

                    </p>
                    </div>
                </div>
            </div>

        </div>

        </div>

        <div className="row vadjust">
            <div className="s_box cardSize mt-5 mb-5">

                <div className="col-lg-4 col-sm-12 col-md-4">
                    <div className='card-image-size'>
                        <div className='imgage_height_system'>
                            <img src="./assets/images/prod/cc/switches/45-series-uni-body-image-01.png" className="img-fluid container-img-top wow def_ani animate__animated animate__fadeIn" alt="" />
                        </div>
                        <div className="container-sb">
                        <h4 className="container-sh4 wow def_ani animate__animated animate__fadeInDown">
                        45 Series Unibody Pilot Lights
                        </h4>
                        <p className='sp wow def_ani_1 animate__animated animate__fadeIn'>
                        •	The new unibody pilot light achieves high brightness. <br />
    •	Unibody type measures only 32mm depth behind the panel. <br />
    •	Flush, Flush with Marking, Extended, and Dome. <br />
    •	Pilot lights can be mounted on panels simply by tightening the locking ring. <br />
    •	Pilot lights are protected from glowing at low or leakage voltages. UPTO 5% <br />

                        </p>
                        </div>
                    </div>
                </div>

            </div>

            <div className="s_box cardSize mt-5 mb-5">

                <div className="col-lg-4 col-sm-12 col-md-4">
                    <div className='card-image-size'>
                        <div className='imgage_height_system'>
                            <img src="./assets/images/prod/cc/switches/foot-switch-01.png" className="img-fluid container-img-top wow def_ani animate__animated animate__fadeIn" alt="" />
                        </div>
                        <div className="container-sb">
                        <h4 className="container-sh4 wow def_ani animate__animated animate__fadeInDown">
                        46 Series Foot Switches
                        </h4>
                        <p className='sp wow def_ani_1 animate__animated animate__fadeIn'>
                        •	Foot switches are available with top protection and complete protection <br />
    •	SPDT, DPTD, 3PDT and 4PDT contacts available <br />
    •	Colored to bright Yellow for easy identification on the shop floor <br />
    •	All models have unique anti skid design to avoid movement on floor <br />
    •	Switches are made of Aluminium die casting <br />

                        </p>
                        </div>
                    </div>
                </div>

            </div>

            <div className="s_box cardSize mt-5 mb-5">

                <div className="col-lg-4 col-sm-12 col-md-4">
                    <div className='card-image-size'>
                        <div className='imgage_height_system'>
                            <img src="./assets/images/prod/cc/switches/01-3.png" className="img-fluid container-img-top wow def_ani animate__animated animate__fadeIn" alt="" />
                        </div>
                        <div className="container-sb">
                        <h4 className="container-sh4 wow def_ani animate__animated animate__fadeInDown">
                        48 Series Limit Switches
                        </h4>
                        <p className='sp wow def_ani_1 animate__animated animate__fadeIn'>
                        •	Available with 18 different operators <br />
    •	Option of with ground and without ground connections <br />
    •	Life of 100,000 operations guaranteed <br />
    •	Actuator can be turned to fit in all 4 positions <br />
    •	Switch base and actuators are made of Aluminium die casting <br />


                        </p>
                        </div>
                    </div>
                </div>

            </div>

        </div>

      </div>

     <Footer/>


    </>
  )
}

export default SwitchesAndLights