export const SData = [
    {
        id: '1',
        image: 'Images/SystemImage/101.jpg',
        name: 'Cube20',
        description: 'Cube20 is a centralized IP20 I/O station with modular function modules and the ability to connect direct to Cube67 modules. The compact modules have a high signal installation of up to 32 channels in one module. This significantly reduces the wiring efforts in the cabinet. Up to 488 I/O points in a width of only 90 centimeters helps to save space in the cabinet.'
    },
    {
        id: '2',
        image: 'Images/SystemImage/102.jpg',
        name: 'Cube20S',
        description: 'Murrelektronik’s modular Cube20S I/O system expands the field-tested Cube family with another IP20 product line with many features. The compact modules are only 12.9 millimeter wide. Thanks to their modular design they can be easily, quickly and individually connected – the integrated  backplane bus connects the modules to power and data.'
    },
    {
        id: '3',
        image: 'Images/SystemImage/103.jpg',
        name: 'Cube67',
        description: 'Cube67 is Murrelektronik’s award winning, modular fieldbus system. It can be customized to meet the specific requirements of an installation solution. Cube67 is characterized by its single cable technology, multi function modules, flexible installation methods and robust, potted circuitry.'
    },
    {
        id: '4',
        image: 'Images/SystemImage/112.jpg',
        name: 'MVK Metal POF',
        description: 'IP67 rated fieldbus modules are an important component of machine and system installations. They replace complex parallel wiring, saving space in the cabinet.'
    },
    {
        id: '5',
        image: 'Images/SystemImage/113.jpg',
        name: 'MVK Metal Safety',
        description: 'Safety is very important in automotive applications. MVK Metal Safety fieldbus modules are a very interesting active safety technology solution. MVK Metal Safety allows for installations to meet Safety Integrity Level 3 (according to IED 61508 and IEC 62061) and Performance Level e (according to EN ISO 13849).'
    },
    {
        id: '6',
        image: 'Images/SystemImage/115.jpg',
        name: 'SOLID67',
        description: 'SOLID67 are the new compact I/O modules from Murrelektronik. They make installation in the field easier and are very attractive for applications with IO-Link sensors and actuators.'
    },
    {
        id: '7',
        image: 'Images/SystemImage/105.jpg',
        name: 'M12 Distribution Systems – Metal',
        description: 'Distribution boxes are designed for consistently plugged solutions from the control into the field. They can be easily separated for assembly and transport.  The distribution boxes feature a metal housing that withstands mechanical and thermal stress.'
    },
    {
        id: '8',
        image: 'Images/SystemImage/106.jpg',
        name: 'M12 Distribution Systems – Plastic',
        description: 'Distribution boxes are designed for consistently plugged solutions from the control into the field. They can be easily separated for assembly and transport.  The boxes are fully potted which makes them sealed and very resistant.'
    },
    {
        id: '9',
        image: 'Images/SystemImage/107.jpg',
        name: 'M8 Distribution Systems',
        description: 'Murrelektronik’s M8 distribution systems allow for economical installation solutions, even in confined spaces. The 30mm wide components can be installed in many different ways.'
    },
    {
        id: '10',
        image: 'Images/SystemImage/108.jpg',
        name: 'MASI20',
        description: 'The installation solution MASI, the AS-Interface system from Murrelektronik, allows easy and low-cost connection of IO signals to superior control systems. MASI allows flexible installation concepts for control cabinet and field, without being tied to rigid topologies.'
    },
    {
        id: '11',
        image: 'Images/SystemImage/109.jpg',
        name: 'MASI67',
        description: 'The installation solution MASI, the AS-Interface system from Murrelektronik, allows easy and low-cost connection of IO signals to superior control systems. MASI allows flexible installation concepts for control cabinet and field, without being tied to rigid topologies.'
    },
    {
        id: '12',
        image: 'Images/SystemImage/110.jpg',
        name: 'MASI68',
        description: 'The installation solution MASI, the AS-Interface system from Murrelektronik, allows easy and low-cost connection of IO signals to superior control systems. MASI allows flexible installation concepts for control cabinet and field, without being tied to rigid topologies.'
    },
    {
        id: '13',
        image: 'Images/SystemImage/114.jpg',
        name: 'MVP12 Steel',
        description: 'Components of machines and installations in the F&B industry have to be easy to clean. This is why Murrelektronik’s MVP12 Steel distribution box is made of high-quality stainless steel.'
    },
    {
        id: '14',
        image: 'Images/SystemImage/115.jpg',
        name: 'SOLID67',
        description: 'SOLID67 are the new compact I/O modules from Murrelektronik. They make installation in the field easier and are very attractive for applications with IO-Link sensors and actuators.'
    },
    {
        id: '15',
        image: 'Images/SystemImage/111.jpg',
        name: 'MVK Metal',
        description: 'IP67 rated fieldbus modules are an important component of machine and system installations. They replace complex parallel wiring, saving space in the cabinet.'
    },
]

