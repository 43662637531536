export const panel = [
    {
        id: 1,
        image: "Images/PanelImage/1.png",
        name: 'Buffer Modules',
        content: 'Voltage drops can cause unpleasant effects: interrupt production processes, cause downtime, and require a system resets. They may even cause data loss and machine damage. Murrelektronik’s buffer modules protect power supplies by bridging the time without power supply or enabling a controlled shut down of machines connected to a PC.'
    },
    {
        id: 2,
        image: "Images/PanelImage/2.jpg",
        name: 'Eco-Rail-2',
        content: 'Eco-Rail-2 power supply units offer manufacturers of machinery and control cabinets the possibility to supply power to their consumers flexibly and economically. These units provide basic functionality and help to monitor the overall costs in the control cabinet.'
    },
    {
        id: 3,
        image: "Images/PanelImage/3.jpg",
        name: 'EMC Filters',
        content: 'EMC filters are essential components that prevent interferences in machines and systems. They reduce the interference emissions while improving immunity from internal and external interferences. Murrelektronik offers a comprehensive range of filters.'
    },
    {
        id: 4,
        image: "Images/PanelImage/4.jpg",
        name: 'Emparro ACCUcontrol',
        content: 'The Emparro ACCUcontrol UPS module rounds out the high-performance Emparro power supply system. In the event of a power failure, it automatically switches to battery operation and prevents machine downtimes. Investing in a UPS system frequently pays for itself the first-time a stoppage is avoided.'
    },
    {
        id: 5,
        image: "Images/PanelImage/5.jpg",
        name: 'Emparro HD – Heavy Duty',
        content: 'The preferred application areas for Emparro HD are areas in which power supply units may be installed in a control cabinet, but are still exposed to a variety of external influences, for example on spreaders (lifting equipment for moving containers), construction cranes, and systems of internal conveyor technology.'
    },
    {
        id: 6,
        image: "Images/PanelImage/6.jpg",
        name: 'Redundancy Module',
        content: 'Power supply systems are often redundantly designed with two power supply units in order to achieve the highest machine run time. Murrelektronik’s active redundancy module, MB Redundancy Balance, decouples two independent power supply units and generates a redundant 24 V DC control voltage.'
    },
    {
        id: 7,
        image: "Images/PanelImage/7.jpg",
        name: 'MICO Fuse',
        content: 'MICO Fuse is the ideal alternative to using individual safety terminals. The compact module features eight sockets for glass tube fuses that can easily be serviced from the front of the module. It protects up to eight channels. The MICO FUSE 24 LED version has additional LEDs that indicate the operating condition of each channel in red and green.'
    },
    {
        id: 8,
        image: "Images/PanelImage/8.jpg",
        name: 'MICO Classic',
        content: 'MICO Classic is the intelligent power distribution module from Murrelektronik for 24 V DC applications. This cabinet component monitors currents on up to four channels. It indicates when approaching the maximum load and it shuts the channel off if there’s a short circuits or overload.'
    },
    {
        id: 9,
        image: "Images/PanelImage/9.jpg",
        name: 'Optocouplers',
        content: 'Optocouplers are used to combine different signal levels or to isolate one signal from another. They provide a optoelectronic signal transfer between input and output, and therefore, they never wear out.'
    },
    {
        id: 10,
        image: "Images/PanelImage/10.jpg",
        name: 'Switches',
        content:'Machines and systems become more and more closely networked. Ethernet-based automation solutions are gaining in importance as switches allow the implementation of flexible topologies. Murrelektronik offers suitable components for networking the Ethernet modules economically and goal-oriented.'
    },
    {
        id: 11,
        image: "Images/PanelImage/11.jpg",
        name: 'MICO+',
        content: 'MICO+ is the intelligent power distribution module from Murrelektronik for 24 V DC applications. This cabinet component monitors currents on up to four channels. It indicates when approaching the maximum load and it shuts the channel off if there’s a short circuits or overload.'
    },
]

