import React from 'react'
import Footer from '../Components/Footer'
import Navbar from '../Components/Navbar'

const AboutUs = () => {
  return (
    <div>
      <Navbar/>


      {/* <!-- BreadCrumbs --> */}
     <div class="container-fluid bg_about_banner">
         <div class="container text-white text_left wow def_ani animate__animated animate__fadeInRight">
             <h2 class="poppins px38 about_paddin">About Us</h2>
             <div class="d-flex justify-content-start align-items-start">
                     <a href="./index.html" class="a_tag_no_dec"><p class="px14 muli">Home</p></a>
                     <p class="px14 muli">&nbsp;&nbsp; &#47; &nbsp;&nbsp;About Us</p>
             </div>
            
             
         </div>
       
     </div>





     {/* <!-- ABOUT THE COMPANY --> */}
     <div class="container-fluid">
         <div class="container p-4 p-lg-5 my-2 my-lg-5">
             <div class="row">
                 <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 text_left">
                     <img src="./assets/images/pages_img/about_us_1.jpeg" class="img-fluid p-3 wow def_ani animate__animated animate__fadeIn"></img>
                     
                 </div>
                 <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 text_left pt-2 px-4 px-lg-2">
                     <h2 class="px32 poppind text_grey my-2 my-lg-0 wow def_ani animate__animated animate__fadeInDown">About The Company</h2>
                     <p class="px15 muli py-3 wow def_ani animate__animated animate__fadeIn">We are one of the leading professional in Industrial Automation Solution providers and Electrical Switchgear Marketing Organizations in Karnataka, catering to various segments of customers which include Panel Builders, Contractors, Industries like Steel, Paper, Water etc. OEMs like Machine tool manufacturers, Special Purpose Machine manufacturers, Industrial Process Automation Group, Packaging Machines, Printing machines. We are a group of Engineers with vast experience in Switchgear Sales. We not only market the products but we also recommend & economize  customers’ requirements.
                    </p>
                    <div class="row py-3">
                        <div class="col-12 col-sm-4">
                            <div class="d-flex flex-column justify-content-start align-items-start">
                                <h2 class="px50n muli text_head_blue">
                                    <span id="count1" class=""></span>
                                </h2>
                                <p class="px20 poppins text_number_count bold_700">Customers</p>
                            </div>
                            
                        </div>
                        
                        <div class="col-12 col-sm-4">
                            <div class="d-flex flex-column justify-content-start align-items-start">
                                <h2 class="px50n muli text_head_blue">
                                    <span id="count2" class=""></span> <span class="px16 muli bold_700">Years</span>
                                </h2>
                                <p class="px20 poppins text_number_count bold_700">Experience</p>
                            </div>
                            
                        </div>
                        <div class="col-12 col-sm-4">
                            <div class="d-flex flex-column justify-content-start align-items-start">
                                <h2 class="px50n muli text_head_blue">
                                    <span id="count3" class=""></span><span class="px16 muli bold_700">K</span>
                                </h2>
                                <p class="px20 poppins text_number_count bold_700">Projects Done</p>
                            </div>
                            
                        </div>

                    </div>

                 </div>
             </div>
         </div>
     </div>



{/* <!-- blue band about us --> */}
<div class="container-fluid bg_blue_about">
  <div class="container p-5">
    <h3 class="poppins px32 bold_700 text-white text-center  mt-2 mb-5 wow def_ani animate__animated animate__slideInDown">Why Choose Us!</h3>
      <div class="row g-0 p-0 m-0 d-flex justify-content-around align-items-center">
        <div class="col-12 col-sm-12 col-md-4 col-xl-4 col-xxl-4  py-2 d-flex flex-column align-items-center justify-content-start">
          <img src="./assets/images/svg/support.svg" class="svg_img zoom_out wow def_ani animate__animated animate__fadeInDown" alt="" />
          <p class="px16 muli text_yellow bold_500 pt-3 wow def_ani animate__animated animate__fadeInUp">Quick Support</p>
        </div>
        <div class="col-12 col-sm-12 col-md-4 col-xl-4 col-xxl-4  py-2 d-flex   flex-column align-items-center justify-content-start">
          <img src="./assets/images/svg/working_process.svg" class="svg_img zoom_out wow def_ani animate__animated animate__fadeInDown" alt="" />
          <p class="px16 muli text_yellow bold_500 pt-3 wow def_ani animate__animated animate__fadeInUp">Fast Working Hour</p>
        </div>
        <div class="col-12 col-sm-12 col-md-4 col-xl-4 col-xxl-4  py-2 d-flex   flex-column align-items-center justify-content-start">
          <img src="./assets/images/svg/dedicated_team.svg" class="svg_img zoom_out wow def_ani animate__animated animate__fadeInDown" alt="" />
          <p class="px16 muli text_yellow bold_500 pt-3 wow def_ani animate__animated animate__fadeInUp">Dedicated Team</p>
        </div>
        <div class="col-12 col-sm-12 col-md-4 col-xl-4 col-xxl-4  py-2 d-flex   flex-column align-items-center justify-content-start">
          <img src="./assets/images/svg/service.svg" class="svg_img zoom_out wow def_ani animate__animated animate__fadeInDown" alt="" />
          <p class="px16 muli text_yellow bold_500 pt-3 wow def_ani animate__animated animate__fadeInUp">Highly Professional Service</p>
        </div>
        <div class="col-12 col-sm-12 col-md-4 col-xl-4 col-xxl-4  py-2 d-flex   flex-column align-items-center justify-content-start">
          <img src="./assets/images/svg/client.svg" class="svg_img zoom_out wow def_ani animate__animated animate__fadeInDown" alt="" />
          <p class="px16 muli text_yellow bold_500 pt-3 wow def_ani animate__animated animate__fadeInUp">99% Client satisfaction</p>
        </div>

  </div>
</div>

</div>





     {/*  carousel part DESKTOP*/}
     <div id="carouselExampleControls" class="carousel slide py-3 desktop_carousel" data-bs-ride="carousel" data-bs-interval="5000">
            <h6 class="poppins px32">Our clients</h6>
        <div class="carousel-inner">
          <div class="carousel-item active">
           

            <div class="row d-flex py-2 m-lg-3 m-md-auto justify-content-around  ">

                <div class="col-4 p-0 col-md-2 col-lg-2 bord_green justify-content-center">
                  <img src="./assets/images/logos_carousel/abb.png"  class="carousel_image d-block w-100 my-1 mx-auto p-3" alt="..." />

                </div>
                <div class="col-4 p-0 col-md-2 col-lg-2 bord_green justify-content-center">
                  <img src="././assets/images/logos_carousel/ace.png"  class="carousel_image d-block w-100 my-1 mx-auto p-3" alt="..."/>     

                </div>
                <div class="col-4 col-md-2 col-lg-2 p-0 bord_green justify-content-center">
                  <img src="./assets/images/logos_carousel/ams.png"  class="carousel_image d-block w-100 my-1 mx-auto p-3" alt="..."/> 

                </div>
                <div class="col-4  col-md-2 col-lg-2 p-0 bord_green justify-content-center" >
                  <img src="./assets/images/logos_carousel/auto_edge.png"  class="d-block w-100 p-3 carousel_image my-1 mx-auto" alt="..."/>

                </div>
                <div class="col-4 col-md-2 col-lg-2 p-0 bord_green justify-content-center">
                  <img src="./assets/images/logos_carousel/beml.png"  class="carousel_image d-block w-100 my-1 mx-auto p-3" alt="..."/> 

                </div>
                
              </div>


          </div>
          <div class="carousel-item">
            

            <div class="row d-flex py-2 m-lg-3 m-md-auto justify-content-around ">

                <div class="col-4 p-0 col-md-2 col-lg-2 bord_green justify-content-center">
                  <img src="./assets/images/logos_carousel/liebher.png"  class="carousel_image d-block w-100 my-1 mx-auto p-3" alt="..."/>

                </div>
                <div class="col-4 p-0 col-md-2 col-lg-2 bord_green justify-content-center">
                  <img src="././assets/images/logos_carousel/luk_logo.png"  class="carousel_image d-block w-100 my-1 mx-auto p-3" alt="..."/>     

                </div>
                <div class="col-4 col-md-2 col-lg-2 p-0 bord_green justify-content-center">
                  <img src="./assets/images/logos_carousel/mag.png"  class="carousel_image d-block w-100 my-1 mx-auto p-3" alt="..."/> 

                </div>
                <div class="col-4  col-md-2 col-lg-2 p-0 bord_green justify-content-center" >
                  <img src="./assets/images/logos_carousel/merk.png"  class="d-block w-100 p-3 carousel_image my-1 mx-auto" alt="..."/>

                </div>
                <div class="col-4 col-md-2 col-lg-2 p-0 bord_green justify-content-center">
                  <img src="./assets/images/logos_carousel/nagel.png"  class="carousel_image d-block w-100 my-1 mx-auto p-3" alt="..."/> 

                </div>
                
              </div> 
          </div>
          <div class="carousel-item">
           

            <div class="row d-flex py-2 m-lg-3 m-md-auto justify-content-around  ">

                <div class="col-4 p-0 col-md-2 col-lg-2 bord_green justify-content-center">
                  <img src="./assets/images/logos_carousel/bfw.png"  class="carousel_image d-block w-100 my-1 mx-auto p-3" alt="..."/>

                </div>
                <div class="col-4 p-0 col-md-2 col-lg-2 bord_green justify-content-center">
                  <img src="././assets/images/logos_carousel/federal.png"  class="carousel_image d-block w-100 my-1 mx-auto p-3" alt="..." />     

                </div>
                <div class="col-4 col-md-2 col-lg-2 p-0 bord_green justify-content-center">
                  <img src="./assets/images/logos_carousel/felsomat.png"  class="carousel_image d-block w-100 my-1 mx-auto p-3" alt="..." /> 

                </div>
                <div class="col-4  col-md-2 col-lg-2 p-0 bord_green justify-content-center" >
                  <img src="./assets/images/logos_carousel/honda.png"  class="d-block w-100 p-3 carousel_image my-1 mx-auto" alt="..." />

                </div>
                <div class="col-4 col-md-2 col-lg-2 p-0 bord_green justify-content-center">
                  <img src="./assets/images/logos_carousel/jktyre.png"  class="carousel_image d-block w-100 my-1 mx-auto p-3" alt="..." /> 

                </div>
                
              </div>


          </div>
          <div class="carousel-item">
           

           <div class="row d-flex py-2 m-lg-3 m-md-auto justify-content-around  ">

               <div class="col-4 p-0 col-md-2 col-lg-2 bord_green justify-content-center">
                 <img src="./assets/images/logos_carousel/nestle.png"  class="carousel_image d-block w-100 my-1 mx-auto p-3" alt="..."/>

               </div>
               <div class="col-4 p-0 col-md-2 col-lg-2 bord_green justify-content-center">
                 <img src="././assets/images/logos_carousel/paper_mill.png"  class="carousel_image d-block w-100 my-1 mx-auto p-3" alt="..." />     

               </div>
               <div class="col-4 col-md-2 col-lg-2 p-0 bord_green justify-content-center">
                 <img src="./assets/images/logos_carousel/skf.png"  class="carousel_image d-block w-100 my-1 mx-auto p-3" alt="..." /> 

               </div>
               <div class="col-4  col-md-2 col-lg-2 p-0 bord_green justify-content-center" >
                 <img src="./assets/images/logos_carousel/toyota.png"  class="d-block w-100 p-3 carousel_image my-1 mx-auto" alt="..." />

               </div>
               <div class="col-4 col-md-2 col-lg-2 p-0 bord_green justify-content-center">
                 <img src="./assets/images/logos_carousel/wevin.png"  class="carousel_image d-block w-100 my-1 mx-auto p-3" alt="..." /> 

               </div>
               
             </div>


         </div>
        </div>
        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>


     {/*  carousel part MOBILE*/}
     <div id="carouselExampleControls" class="carousel slide py-3 mobile_carousel" data-bs-ride="carousel" data-bs-interval="1000">
            <h6 class="poppins px32">Our clients</h6>
        <div class="carousel-inner">
          <div class="carousel-item active">
           

            <div class="row d-flex py-2 m-lg-3 m-md-auto justify-content-around  ">

                <div class="col-4 col-md-2 col-lg-2 p-0 bord_green justify-content-center">
                  <img src="./assets/images/logos_carousel/ams.png"  class="carousel_image d-block w-100 my-1 mx-auto p-3" alt="..."/> 

                </div>
                <div class="col-4  col-md-2 col-lg-2 p-0 bord_green justify-content-center" >
                  <img src="./assets/images/logos_carousel/auto_edge.png"  class="d-block w-100 p-3 carousel_image my-1 mx-auto" alt="..."/>

                </div>
                <div class="col-4 col-md-2 col-lg-2 p-0 bord_green justify-content-center">
                  <img src="./assets/images/logos_carousel/beml.png"  class="carousel_image d-block w-100 my-1 mx-auto p-3" alt="..."/> 

                </div>
                
              </div>


          </div>
          <div class="carousel-item">
            

            <div class="row d-flex py-2 m-lg-3 m-md-auto justify-content-around ">

                
                <div class="col-4 col-md-2 col-lg-2 p-0 bord_green justify-content-center">
                  <img src="./assets/images/logos_carousel/mag.png"  class="carousel_image d-block w-100 my-1 mx-auto p-3" alt="..."/> 

                </div>
                <div class="col-4  col-md-2 col-lg-2 p-0 bord_green justify-content-center" >
                  <img src="./assets/images/logos_carousel/merk.png"  class="d-block w-100 p-3 carousel_image my-1 mx-auto" alt="..."/>

                </div>
                <div class="col-4 col-md-2 col-lg-2 p-0 bord_green justify-content-center">
                  <img src="./assets/images/logos_carousel/nagel.png"  class="carousel_image d-block w-100 my-1 mx-auto p-3" alt="..."/> 

                </div>
                
              </div> 
          </div>
          <div class="carousel-item">
           

            <div class="row d-flex py-2 m-lg-3 m-md-auto justify-content-around  ">
              

                <div class="col-4 p-0 col-md-2 col-lg-2 bord_green justify-content-center">
                  <img src="./assets/images/logos_carousel/bfw.png"  class="carousel_image d-block w-100 my-1 mx-auto p-3" alt="..."/>

                </div>
                <div class="col-4 p-0 col-md-2 col-lg-2 bord_green justify-content-center">
                  <img src="././assets/images/logos_carousel/federal.png"  class="carousel_image d-block w-100 my-1 mx-auto p-3" alt="..." />     

                </div>
                <div class="col-4 col-md-2 col-lg-2 p-0 bord_green justify-content-center">
                  <img src="./assets/images/logos_carousel/felsomat.png"  class="carousel_image d-block w-100 my-1 mx-auto p-3" alt="..." /> 

                </div>
              
                
              </div>


          </div>
          <div class="carousel-item">
        
           

           <div class="row d-flex py-2 m-lg-3 m-md-auto justify-content-around  ">
         
                

               <div class="col-4 p-0 col-md-2 col-lg-2 bord_green justify-content-center">
                 <img src="./assets/images/logos_carousel/nestle.png"  class="carousel_image d-block w-100 my-1 mx-auto p-3" alt="..."/>

               </div>
               <div class="col-4 p-0 col-md-2 col-lg-2 bord_green justify-content-center">
                 <img src="././assets/images/logos_carousel/paper_mill.png"  class="carousel_image d-block w-100 my-1 mx-auto p-3" alt="..." />     

               </div>
               <div class="col-4 col-md-2 col-lg-2 p-0 bord_green justify-content-center">
                 <img src="./assets/images/logos_carousel/skf.png"  class="carousel_image d-block w-100 my-1 mx-auto p-3" alt="..." /> 

               </div>
               
               
             </div>
             </div>

             <div class="carousel-item">
           

            <div class="row d-flex py-2 m-lg-3 m-md-auto justify-content-around  ">
              
            <div class="col-4 p-0 col-md-2 col-lg-2 bord_green justify-content-center">
                  <img src="././assets/images/logos_carousel/luk_logo.png"  class="carousel_image d-block w-100 my-1 mx-auto p-3" alt="..."/>     

                </div>
           <div class="col-4 p-0 col-md-2 col-lg-2 bord_green justify-content-center">
                  <img src="./assets/images/logos_carousel/abb.png"  class="carousel_image d-block w-100 my-1 mx-auto p-3" alt="..." />

                </div>
                <div class="col-4 p-0 col-md-2 col-lg-2 bord_green justify-content-center">
                  <img src="././assets/images/logos_carousel/ace.png"  class="carousel_image d-block w-100 my-1 mx-auto p-3" alt="..."/>     

                </div>
              
                
              </div>


          </div>
         <div class="carousel-item">
           

            <div class="row d-flex py-2 m-lg-3 m-md-auto justify-content-around  ">
              
            <div class="col-4  col-md-2 col-lg-2 p-0 bord_green justify-content-center" >
                  <img src="./assets/images/logos_carousel/honda.png"  class="d-block w-100 p-3 carousel_image my-1 mx-auto" alt="..." />

                </div>
                <div class="col-4 col-md-2 col-lg-2 p-0 bord_green justify-content-center">
                  <img src="./assets/images/logos_carousel/jktyre.png"  class="carousel_image d-block w-100 my-1 mx-auto p-3" alt="..." /> 

                </div>
           <div class="col-4 p-0 col-md-2 col-lg-2 bord_green justify-content-center">
                  <img src="./assets/images/logos_carousel/liebher.png"  class="carousel_image d-block w-100 my-1 mx-auto p-3" alt="..."/>

                </div>
              
                
              </div>


          </div>
          <div class="carousel-item">
           

           <div class="row d-flex py-2 m-lg-3 m-md-auto justify-content-around  ">
           <div class="col-4  col-md-2 col-lg-2 p-0 bord_green justify-content-center" >
                 <img src="./assets/images/logos_carousel/toyota.png"  class="d-block w-100 p-3 carousel_image my-1 mx-auto" alt="..." />

               </div>
               <div class="col-4 col-md-2 col-lg-2 p-0 bord_green justify-content-center">
                 <img src="./assets/images/logos_carousel/wevin.png"  class="carousel_image d-block w-100 my-1 mx-auto p-3" alt="..." /> 
               </div>
             
        
             
               
             </div>


         </div>
        </div>
        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>
     


   
   <Footer/>
    </div>
  )
}

export default AboutUs