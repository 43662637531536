import React from 'react'
import Navbar from '../../Components/Navbar'
import { Link } from "react-router-dom";
import Footer from '../../Components/Footer';

function terminalenclosure() {
  return (
    <>
    <Navbar/>
      <div className=' Interfacebackground text-white'>
        <div className='container System pt-5 mt-3  wow def_ani animate__animated animate__fadeInRight'>
        <h1 className='container System text-white'>Terminal Enclosures</h1>
          <Link className='container System' to='/'>
            <span class="text-white">Home</span></Link>
          <span class="text-white">/</span>
          <a className='container System'>
            <span class="text-white">product</span></a>
          <span class="text-white">/</span>
          <a className='container System'>
            <span class="text-white">Terminal Enclosures</span></a>
        </div>
      </div>

      <div className="container">
        <div className="row mb-5">

            <div className="col-12 col-md-4 col-lg-4">
                <div className="row">
                    <div className="col-12 mb-4">
                    <img src="./assets/images/prod/other/Terminal-Enclosures-image.png" className="img_control_prod img-fluid container-img-top wow def_ani animate__animated animate__fadeIn" alt="" />
                    </div>
                    <div className="col-12">
                    <h4 className="container-sh4 wow def_ani animate__animated animate__fadeInDown">
                    Terminal Enclosures

                    </h4>
                    <p className='sp wow def_ani_1 animate__animated animate__fadeIn'>
                    •	Effortless wall-mounting thanks to rear panel with holes, prepared for wall mounting bracket or direct wall mounting. <br/>
•	Seven tank phosphating process powder coating. <br/>
•	Flexible interior configuration thanks to integral perforated system strips with 12.5mm pitch pattern. <br/>
•	Maximum flexibility in cable routing. <br/>
•	Available in serval sizes. <br/>


                    </p>
                    </div>
                </div>
            </div>
            
          
        </div>
    </div>




     <Footer/>


    </>
  )
}

export default terminalenclosure 