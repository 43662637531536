import './App.css';
import { Routes, Route } from 'react-router-dom';
import Connection_Technology from './Pages/Product/Connection_Technology'
import Interface from './Pages/Product/Interface';
import Panel_Cabinet from './Pages/Product/Panel_Cabinet';
import System from './Pages/Product/System';
import Automotive from './Pages/Industry/Automotive';
import Food_Beverage from './Pages/Industry/Food_Beverage';
import Storage_Logisctic from './Pages/Industry/Storage_Logisctic';
import Robotics from './Pages/Industry/Robotics';
import MachineTools from './Pages/Industry/MachineTools';
import Home from './Pages/Home';
import AboutUs from './Pages/AboutUs';
import Download from './Pages/Download';
import ContactUs from './Pages/ContactUs';
import Footer from './Components/Footer';
import MobileApplication from './Pages/Industry/MobileApplication.js';


import RelaysAndSockets from './Pages/Product/RelaysAndSockets';
import SwitchesAndLights from './Pages/Product/SwitchesAndLights';
import RemoteControl from './Pages/Product/RemoteControl';
import Annunciation from './Pages/Product/Annunciation';
import SignalTower from './Pages/Product/SignalTower';
import TerminalBlocks from './Pages/Product/TerminalBlocks';
import RelayInterface from './Pages/Product/RInterface';
import DinRail from './Pages/Product/DinRail';
import TerminalEnclosure from './Pages/Product/TerminalEnclosure';
import FanFailureDetector from './Pages/Product/FanFailureDetector';
import Mosaic from './Pages/Product/Mosaic';
import OperatorWorkstation from './Pages/Product/OperatorWorkstation';



function App() {
  return (
    <div className="App">

      
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/aboutUs" element={<AboutUs/>} />
 
        <Route path="/relays" element={<RelaysAndSockets/>} />
        <Route path="/switches" element={<SwitchesAndLights/>} />
        <Route path="/remotecontrol" element={<RemoteControl/>} />
        <Route path="/annunciation" element={<Annunciation/>} />
        <Route path="/signaltower" element={<SignalTower/>} />

        <Route path="/terminalblocks" element={<TerminalBlocks/>} />
        <Route path="/relayinterface" element={<RelayInterface/>} />
        
        <Route path="/dinrail" element={<DinRail/>} />
        <Route path="/terminalenclosure" element={<TerminalEnclosure/>} />
        <Route path="/FanFailureDetector" element={<FanFailureDetector/>} />
        
        <Route path="/Mosaic" element={<Mosaic/>} />
        <Route path="/OperatorWorkstation" element={<OperatorWorkstation/>} />

        <Route path="/connection_Technology" element={<Connection_Technology/>} />
        <Route path="/interface" element={<Interface />} />
        <Route path="/panel_Cabinet" element={<Panel_Cabinet/>} />
        <Route path="/system" element={<System/>} />

        <Route path="/automotive" element={<Automotive/>} />
        <Route path="/food_beverage" element={<Food_Beverage/>} />
        <Route path="/storage_logistics" element={<Storage_Logisctic/>} />
        <Route path="/mobileApplication" element={<MobileApplication/>} />
        <Route path="/robotics" element={<Robotics/>} />
        <Route path="/machineTool" element={<MachineTools/>} />

        <Route path="/download" element={<Download/>} />

        <Route path="/contactUs" element={<ContactUs/>} />


      </Routes>
      {/* <Footer/> */}
    </div>
  );
}

export default App;
