import React from 'react'
import Navbar from '../Components/Navbar'
import { useState } from 'react';
import validator from 'validator';
import Footer from '../Components/Footer';

const ContactUs = () => {
  const [mobile, setMobile] = useState("");
 const [email,setEmail]=useState("");
 const [name,setName]=useState("");
 const [country,setCountry]=useState("");
//  const [product,setProduct]=useState("");
 const [message,setMessage]=useState("");
 const [errorObj,setErrorObj]=useState({})

 let errors = {}
 
 const runValidator =()=>{
    if(email.length === 0){
        errors.email = "Email can't be blank"
    }
    else if(!validator.isEmail(email)){
        errors.email="Email is not valid"
    }
    if(name?.length === 0){
        errors.name = "name can't be empty" 
    }
    if(mobile?.length === 0  || mobile?.length !== 10){
        errors.mobile = "Mobile number not be empty or have atleast 10 charcter"
    }    
    if(country?.length === 0 ){
        errors.country = "country should not be empty"
    }
    // if(product?.length === 0 ){
    //     errors.product ="product should not be empty"
    // }
    if(message.length === 0 ){
        errors.message = "Message should not be empty"
    }
 }


 const handleChange=(e)=>{
   setName(e.target.value)
   }
 
 
 const btn=(e)=>{
    e.preventDefault()
    runValidator()
    if(Object.keys(errors).length === 0){
        setErrorObj({})
        const formData={
            name:name,
            mobile:mobile,
            email:email,
            country :country,
            // product:product,
            message:message
        }
        console.log(formData)
        setCountry("")
        setEmail("")
        setMessage("")
        // setProduct("")
        setName("")
        setMobile("")
     }
     else {
        setErrorObj(errors)
     }
 }
  return (
    <div>


      <Navbar/>
        {/* <!-- BreadCrumbs --> */}
     <div class="container-fluid bg_contact_banner">
         <div class="container text-white text_left wow def_ani animate__animated animate__fadeInRight">
             <h2 class="poppins px38 about_paddin">Contact Us</h2>
             <div class="d-flex justify-content-start align-items-start">
                     <a href="./index.html" class="a_tag_no_dec"><p class="px14 muli">Home</p></a>
                     <p class="px14 muli">&nbsp;&nbsp; &#47; &nbsp;&nbsp;Contact Us</p>
             </div>
            
             
         </div>
     </div>


     {/* <!-- CONTACT INFO --> */}
<div class="container-fluid bg_white_grey">
  <div class="container p-3 p-sm-5">
    <h6 class="px32 poppins bold_500 text_grey text-center pt-3 pb-5">Contact Info</h6>
    <div class="row d-flex justify-content-center align-items-start">
      {/* <div class="col-1"></div> */}

      <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4 d-flex flex-column justify-content-center align-items-center">
        <p class="poppins px32 wow def_ani animate__animated animate__fadeInDown"><i class="fa-solid fa-mobile-screen-button"></i></p>
        <h6 class="poppins px26 bold_500 text_grey wow def_ani animate__animated animate__fadeInUp">Call Us</h6>
        <a href="tel:+919845373148" class="a_tag_no_dec_2 text_grey">
          <p class="px14 muli my-2 wow def_ani animate__animated animate__fadeInUp"> +91-9845373148</p>
        </a>
      </div>
      <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4 d-flex flex-column justify-content-center align-items-center">
        <p class="poppins px32 wow def_ani animate__animated animate__fadeInDown"><i class="fa-solid fa-envelope"></i></p>
        <h6 class="poppins px26 bold_500 text_grey wow def_ani animate__animated animate__fadeInUp">Mail Us</h6>
        <a href="mailto:info@snisindia.in" class="a_tag_no_dec_2 text_grey">
          <p class="px14 muli my-2 wow def_ani animate__animated animate__fadeInUp">info@snisindia.in</p>
      </a>
        
      </div>
      <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4 d-flex flex-column justify-content-center align-items-center">
        <p class="poppins px32 wow def_ani animate__animated animate__fadeInDown"><i class="fa-solid fa-location-dot"></i></p>
        <h6 class="poppins px26 bold_500 text_grey wow def_ani animate__animated animate__fadeInUp">Address</h6>
          <p class="px14 muli my-2 text_grey text-center  wow def_ani animate__animated animate__fadeInUp">NO 85/1 C BLOCK R H C S LAYOUT SRIGANDADAKAVAL BANGALORE 560091</p>
      </div>
      {/* <div class="col-1"></div> */}



    </div>
  </div>
</div>


      {/* get in touch  */}
      <div class="container-fluid bg_get_in_touch">
        <div class="container">
          <h2 class="poppins px24 text_grey bold_500 pt-5 text-center ">Get In Touch</h2>
          <p class="px15 muli pt-3 text-center wow def_ani animate__animated animate__fadeInUp">Drop in your contact details and get a free consultation from our experts. You can talk to them about your industry automation  needs and get a competitive quote.</p>
          <div class="row px-0 px-lg-5 mx-0 mx-lg-5 d-flex justify-content-center align-items-center">
            
           <div class="col-10 col-sm-10 col-md-10 col-lg-10 colxl-10 col-xxl-10 pt-0 pt-lg-5 px-3 px-lg-5">
             <div class="row">
              <div class="col-12 col-sm-12 col-md-6 mb-3 pt-1">
                <input type="name" class="form-control py-3" placeholder="Name" aria-label="Name" id="user_name" value={name} onChange={handleChange}/>
                <span>{errorObj.name && <span style={{color:'red'}}>{errorObj.name}</span>}</span>
              
              </div>
              <div class="col-12 col-sm-12 col-md-6 mb-3 pt-1">
                <input type="email" class="form-control py-3"  placeholder="E-mail" id="email_id" value={email} onChange={(e)=>setEmail(e.target.value)}/>
                <span>{errorObj.email && <span style={{color:'red'}}>{errorObj.email}</span>}</span>
              </div>
              <div class="col-12 col-sm-12 col-md-6 mb-3 pt-1">
                <input type="number" class="form-control py-3" placeholder="Phone Number" aria-label="Phone Number" id="phone_number" value={mobile} onChange={(e) => setMobile(e.target.value) }/>
                <span>{errorObj.mobile && <span style={{color:'red'}}>{errorObj.mobile}</span>}</span>
              </div>
              <div class="col-12 col-sm-12 col-md-6 mb-3 pt-1">
                <input type="text" class="form-control py-3" placeholder="Country" aria-label="Name"id="country" value={country} onChange={(e) =>setCountry(e.target.value)} />
                <span>{errorObj.country && <span style={{color:'red'}}>{errorObj.country}</span>}</span>
              </div>
             </div>
           </div>
           <div class="col-10 col-sm-10 col-md-10 col-lg-10 colxl-10 col-xxl-10 pt-3 px-3 px-lg-5">
              <textarea class="form-control" id="exampleFormControlTextarea1" rows="6" placeholder="Your Message Here" value={message} onChange={(e) =>setMessage(e.target.value)}></textarea>
              <span>{errorObj.message && <span style={{color:'red'}}>{errorObj.message}</span>}</span>
           </div>
           <div class="col-10 col-sm-12 mb-4">
            <div class="col-12 mt-4 mt-md-5 pt-2 pt-md-3 pb-2 pb-lg-3">
              <button type="button" class="btn submit_button btn-md" onClick={btn}>
                <span class="text-white px16 muli" >Submit</span>
               </button>
            </div>
           </div>
        
        
          
      
            {/* <div class="col-12 col-sm-12 col-md-6 col-lg-6 colxl-6 col-xxl-6 pt-2 pt-md-5 d-flex justify-content-center align-items-center">
             <div class="col-12 mb-2 mb-md-3">

               <textarea class="form-control border_rad_0" id="exampleFormControlTextarea1" rows="5" placeholder="Your Message Here"></textarea>
             </div>
            </div>
            <div class="col-12 mb-4">
             <div class="col-12 mt-4 mt-md-5 pt-2 pt-md-3 ">
               <button type="button" class="btn submit_button btn-md">
                 <span class="text-white px16 muli">Submit</span>
                </button>
             </div>
            </div> */}
         
         
           
       </div>

        </div>
      </div>




<Footer/>
    </div>

  )
}

export default ContactUs