import React from 'react'
import Navbar from '../../Components/Navbar'
import { Link } from "react-router-dom";
import Footer from '../../Components/Footer';

function OperatorWorkstation() {
  return (
    <>
    <Navbar/>
      <div className=' Interfacebackground text-white'>
        <div className='container System pt-5 mt-3  wow def_ani animate__animated animate__fadeInRight'>
        <h1 className='container System text-white'>Operator Workstation and Console</h1>
          <Link className='container System' to='/'>
            <span class="text-white">Home</span></Link>
          <span class="text-white">/</span>
          <a className='container System'>
            <span class="text-white">product</span></a>
          <span class="text-white">/</span>
          <a className='container System'>
          <span class="text-white">Control Room Line</span></a>
        </div>
      </div>

  
      <div className="container">
        <div className="row mb-5">

            


            <div className="col-12 col-md-4 col-lg-4">
                <div className="row">
                    <div className="col-12 mb-4">
                    <img src="./assets/images/prod/cr/operatorconsole/OC-A.png" className="img_control_prod img-fluid container-img-top wow def_ani animate__animated animate__fadeIn" alt="" />
                    </div>
                    <div className="col-12">
                    <h4 className="container-sh4 wow def_ani animate__animated animate__fadeInDown">
                    AURA SERIES-1, SERIES-2, SERIES-3, SERIES-4, SERIES-5

                    </h4>
                    <p className='sp wow def_ani_1 animate__animated animate__fadeIn'>
                    •	With over 30 years of experience in Console Panel’s Making. <br/>
•	Move from sitting to standing in mere seconds with our easy-to-use gas lift system or electrical lift. <br/>
•	Werner has more than 200 installations in various control rooms. <br/>
•	Can supply entire console with wiring & integrating all equipment. <br/>

                    </p>
                    </div>
                </div>
            </div>
            

        </div>
    </div>


     <Footer/>


    </>
  )
}

export default OperatorWorkstation 