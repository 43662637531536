import React, { useEffect, useState } from 'react'

const Loader = () => {
    return (   
                    <div class="loading">
                        <img src='./assets/images/SNIS_logo.png' alt='loader' style={{marginBottom:'20px',background:'#000',width:'200px',height:'200px'}}/>
                    </div>
    )
}

export default Loader