import React from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../Components/Navbar';
import { useState } from 'react';
import validator from 'validator';
import Loader from '../Components/Loader';
import Footer from '../Components/Footer';

const Home = () => {
  const [loading, setLoading] = useState(true)
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [country, setCountry] = useState("");
  //  const [product,setProduct]=useState("");
  const [message, setMessage] = useState("");
  const [errorObj, setErrorObj] = useState({})

  setTimeout(function () {
    setLoading(false)
  }, 650);

  let errors = {}

  const runValidator = () => {
    if (email.length === 0) {
      errors.email = "Email can't be blank"
    }
    else if (!validator.isEmail(email)) {
      errors.email = "Email is not valid"
    }
    if (name?.length === 0) {
      errors.name = "name can't be empty"
    }
    if (mobile?.length === 0 || mobile?.length !== 10) {
      errors.mobile = "Mobile number not be empty or have atleast 10 charcter"
    }
    if (country?.length === 0) {
      errors.country = "country should not be empty"
    }
    // if(product?.length === 0 ){
    //     errors.product ="product should not be empty"
    // }
    if (message.length === 0) {
      errors.message = "Message should not be empty"
    }
  }


  const handleChange = (e) => {
    setName(e.target.value)
  }


  const btn = (e) => {
    e.preventDefault()
    runValidator()
    if (Object.keys(errors).length === 0) {
      setErrorObj({})
      const formDataHome = {
        name: name,
        mobile: mobile,
        email: email,
        country: country,
        // product:product,
        message: message
      }
      console.log(formDataHome)
      setCountry("")
      setEmail("")
      setMessage("")
      // setProduct("")
      setName("")
      setMobile("")
    }
    else {
      setErrorObj(errors)
    }
  }
  return (
    <div>
 <div>
      {loading ? <div>
        <Loader />
      </div> : <div>
        <div>
          {/* <!-- UPPER CONTACT  BAR --> */}
          <div class="container-fluid bg__header_sky_blue top_contact_height">
            <div class="container-md">

              <div class="row">
                <div className="col-12 col-md-6 col-lg-8">
                  <div className="row">
                  <div class="col-12 col-md-8 col-lg-6 d-flex flex-row align-items-start justify-content-start text_left">
                  <a href="mailto:info@snisindia.in" class="a_tag_no_dec pe-4">
                    <p class="px14 muli my-2"><i class="fa-solid fa-envelope pe-2"></i> sales@snisindia.in</p>
                  </a>
                  <a href="tel:+919845373148" class="a_tag_no_dec">
                    <p class="px14 muli my-2"><i class="fa-solid fa-phone pe-2"></i> +919845373148</p>
                  </a>
                </div>
                  </div>

                  <div class="row text-white">
                <div class="col-12 col-md-12 col-lg-8 col-xl-6 d-flex flex-row align-items-start justify-content-start text_left">
                  <p class="px14 muli">
                    <i class="fa-solid fa-earth-americas pe-2"></i> NO 85/1 C BLOCK R H C S LAYOUT SRIGANDADAKAVAL BANGALORE 560091
                  </p>
                </div>
              </div>
                </div>


                <div className="col-12 col-md-6 col-lg-4">
                  <div className="row">
                    <div className="col-12">
                    <p className="px-24 mt-1">OUR PARTNERS</p>
                    </div>
                  </div>
                  <div className="row d-flex align-items-top">

                    {/* <div className="col-4 col-md-4 col-lg-3">
                    </div> */}

                    <div className="col-12 col-md-5 col-lg-5">
                    <img src="./assets/images/wernerlogo.png" class=" topbarimg1 img-fluid wow def_ani animate__animated animate__fadeInLeft" alt="" />
                    </div>

                    <div className="col-12 col-md-5 col-lg-5">
                    <img src="./assets/images/Home_carousel/murr_green_logo_edit.png" class=" topbarimg img-fluid wow def_ani animate__animated animate__fadeInRight" alt="" />
                      
                    </div>
                  </div>
                </div>
              
              </div>

              


            </div>
          </div>


          {/* navbar  */}
          <Navbar />


          {/* <!-- home page carousel --> */}

          <div id="carouselExampleFade" class="carousel slide carousel-fade carousel_height" data-bs-ride="carousel">
            <div class="carousel-inner">
              <div class="carousel-item active">
                <img src="./assets/images/Home_carousel/home_banner_2.jpeg" class="d-block w-100 home_image_class" alt="..." />
              </div>
              <div class="carousel-item">
                <img src="./assets/images/Home_carousel/home_banner_3.jpeg" class="d-block w-100 home_image_class" alt="..." />
              </div>
              <div class="carousel-item">
                <img src="./assets/images/Home_carousel/home_banner_1.jpeg" class="d-block w-100 home_image_class" alt="..." />
              </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>

          {/* ABOUT SNIS PILLS */}
          <div class="container py-5">
            <div class="px-5">


              <ul class="nav nav-pills mb-3 row d-flex justify-content-center align-items-center wow def_ani animate__animated animate__fadeIn" id="pills-tab" role="tablist">
                <li class="nav-item col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4 pb-1 py-md-0 d-flex justify-content-center align-items-center " role="presentation">
                  <button class="nav-link active  pills_buttons custom_bg px20 poppins bold_500" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">About SNIS</button>
                </li>
                <li class="nav-item col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4 pb-1 py-md-0 d-flex justify-content-center align-items-center" role="presentation">
                  <button class="nav-link pills_buttons custom_bg px20 poppins bold_500" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Why Choose Us</button>
                </li>
                <li class="nav-item col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4 pb-1 py-md-0 d-flex justify-content-center align-items-center" role="presentation">
                  <button class="nav-link pills_buttons custom_bg px20 poppins bold_500" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">Our Vision</button>
                </li>
              </ul>
              <div class=" tab-content mt-5" id="pills-tabContent">
                <div class="tab-pane fade show active " id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                  <div class="row">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-5 d-flex align-items-center justify-content-center">
                      <img src="./assets/images/Home_carousel/video_img.png" class="img-fluid" />
                    </div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-7 text_grey text_left">
                      <h6 class="px22 poppins py-2 text_black">About SNIS</h6>
                      <p class="px16 muli py-2">We are one of the leading professional in Industrial Automation Solution providers and Electrical Switchgear Marketing Organizations in Karnataka, catering to various segments of customers which include Panel Builders, Contractors, Industries like Steel, Paper, Water etc.</p>
                      <p class="px16 muli py-2">OEMs like Machine tool manufacturers, Special Purpose Machine manufacturers, Industrial Process Automation Group, Packaging Machines, Printing machines.</p>
                      <div class="row g-0 py-1">
                        <div class="col-12 com-sm-12 col-md-12 col-lg-6 d-flex flex-column align-items-start justify-content-start ">
                          <p class="px16 muli"><i class="fa-solid fa-check text_foot_blue pe-2"></i>Dedicated Team Member</p>
                          <p class="px16 muli"><i class="fa-solid fa-check text_foot_blue pe-2"></i>Latest Technology</p>
                          <p class="px16 muli"><i class="fa-solid fa-check text_foot_blue pe-2"></i>Free Estimate</p>
                        </div>
                        <div class="col-12 com-sm-12 col-md-12 col-lg-6 d-flex flex-column align-items-start justify-content-start">
                          <p class="px16 muli"><i class="fa-solid fa-check text_foot_blue pe-2"></i>Creative Design &amp; Development</p>
                          <p class="px16 muli"><i class="fa-solid fa-check text_foot_blue pe-2"></i>Faster Work &amp; Delivery</p>
                          <p class="px16 muli"><i class="fa-solid fa-check text_foot_blue pe-2"></i>Clients Support</p>

                        </div>
                      </div>

                    </div>
                  </div>
                </div>
                <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                  <div class="row">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-7 text_grey text_left">
                      <h6 class="px22 poppins py-2 text_black">Why Choose SNIS</h6>
                      <p class="px16 muli py-3">We are a group of Engineers with vast experience in Switchgear Sales.</p>
                      <p class="px16 muli py-3">We not only market the products but we also recommend &amp; economize  customers’ requirements.</p>
                      <p class="px16 muli py-3">Sri Nidhi Industrial Suppliers was established in the year 2003 by Mr. K.Mohan in Bangalore and has been associated with MURR ELEKTRONIK AND MURR PLASTIk.</p>
                    </div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-5 d-flex align-items-center justify-content-center">
                      <img src="./assets/images/Home_carousel/home_pill_2.png" class="img-fluid" />
                    </div>
                  </div>
                </div>
                <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                  <div class="row">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-7 text_grey text_left">
                      <h6 class="px22 poppins py-2 text_black">Our Vision</h6>
                      <p class="px16 muli py-4">We aim at customer satisfaction and this has been the strength of our company since the day we started. We have been supporting our customers in every possible way in terms of introducing new technology, providing high quality products, providing excellent service and pricing.</p>
                    </div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-5 d-flex align-items-center justify-content-center">
                      <img src="./assets/images/Home_carousel/home_pills_3.jpeg" class="img-fluid" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>





          {/* <!-- OUR WORKING PROCESS --> */}
          <div class="container-fluid  bg_color g-0 my-5 pt-5">
            <div class="bg_banner_1 background_tint">
              <h6 class="px32 poppins text-center text-white pt-4">Our Working Process</h6>

              <div class="row d-flex justify-content-center align-items-center py-1">
                <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3 working_hover d-flex flex-column align-items-center justify-content-around py-3">
                  <img src="./assets/images/planing.png" class="z_index_2 img-fluid zoom_out wow def_ani animate__animated animate__fadeInLeft" alt="" />
                  <p class="poppins bold_500 px22  py-3 wow def_ani animate__animated animate__fadeInLeft">Planing</p>
                </div>
                <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3 working_hover d-flex flex-column align-items-center justify-content-around py-3">
                  <img src="./assets/images/design.png" class="z_index_2 img-fluid zoom_out wow def_ani animate__animated animate__fadeInUp" alt="" />
                  <p class="poppins bold_500 px22 py-3 wow def_ani animate__animated animate__fadeInUp">Design</p>

                </div>
                <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3 working_hover d-flex flex-column align-items-center justify-content-around py-3">
                  <img src="./assets/images/develop.png" class="z_index_2 img-fluid zoom_out wow def_ani animate__animated animate__fadeInDown" alt="" />
                  <p class="poppins bold_500 px22 py-3 wow def_ani animate__animated animate__fadeInDown">Develop</p>

                </div>
                <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3 working_hover d-flex flex-column align-items-center justify-content-around py-3">
                  <img src="./assets/images/deliver.png" class="z_index_2 img-fluid zoom_out wow def_ani animate__animated animate__fadeInRight" alt="" />
                  <p class="poppins bold_500 px22 py-3 wow def_ani animate__animated animate__fadeInRight">Deliver</p>

                </div>
              </div>

            </div>
          </div>



        <section className="container">
          <div className="row">
            <div className="col-12">
              <div className="row">
                <div className="col-12">
                <h2 class="text-center px38n poppins py-5 wow def_ani animate__animated animate__fadeInDown">Our Products</h2>
                </div>
              </div>
            <div className="row d-flex justify-content-around">

              <div className="col-12 col-md-3 col-lg-3">
                <div className="row">
                  <div className="col-12">
                    <img src="./Images/ConnectionTechnologyImage/16.jpg" className="img-fluid prod_sec_img_cntrl heigProd p-2" alt="" />
                  </div>
                  <div className="col-12">
                    <p className="p-2 toBold">
                    Sensor Actuator Cables
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-3 col-lg-3">
                <div className="row">
                  <div className="col-12">
                    <img src="./Images/SystemImage/103.jpg" className="img-fluid prod_sec_img_cntrl heigProd p-2" alt="" />
                  </div>
                  <div className="col-12">
                    <p className="p-2 toBold">
                    Cube67
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-3 col-lg-3">
                <div className="row">
                  <div className="col-12">
                    <img src="./Images/SystemImage/115.jpg" className="img-fluid prod_sec_img_cntrl heigProd p-2" alt="" />
                  </div>
                  <div className="col-12">
                    <p className="p-2 toBold">
                    SOLID67
                    </p>
                  </div>
                </div>
              </div>


              </div>
              
              <div className="row d-flex justify-content-around mt-5">

              <div className="col-12 col-md-3 col-lg-3">
                <div className="row">
                  <div className="col-12">
                    <img src="/Images/SystemImage/106.jpg" className="img-fluid prod_sec_img_cntrl heigProd p-2" alt="" />
                  </div>
                  <div className="col-12">
                    <p className="p-2 toBold">
                    M12 Distribution Systems – Plastic
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-3 col-lg-3">
                <div className="row">
                  <div className="col-12">
                    <img src="/Images/PanelImage/8.jpg" className="img-fluid prod_sec_img_cntrl heigProd p-2" alt="" />
                  </div>
                  <div className="col-12">
                    <p className="p-2 toBold">
                    MICO Classic
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-3 col-lg-3">
                <div className="row">
                  <div className="col-12">
                    <img src="/Images/PanelImage/10.jpg" className="img-fluid prod_sec_img_cntrl heigProd p-2" alt="" />
                  </div>
                  <div className="col-12">
                    <p className="p-2 toBold">
                    Switches
                    </p>
                  </div>
                </div>
              </div>


              </div>

              <div className="row d-flex justify-content-around mt-5">

                <div className="col-12 col-md-3 col-lg-3">
                  <div className="row">
                    <div className="col-12">
                      <img src="./assets/images/prod/cc/switches/32-series-jumbo-dome-pilot-light-4.png" className="img-fluid prod_sec_img_cntrl heigProd p-2" alt="" />
                    </div>
                    <div className="col-12">
                      <p className="p-2 toBold">
                      32 Series Jumbo Dome Pilot Lights
                      </p>
                    </div>
                  </div>
                </div>
                
                <div className="col-12 col-md-3 col-lg-3">
                  <div className="row">
                    <div className="col-12">
                      <img src="./assets/images/prod/cc/relays/21-series-relay.png" className="img-fluid prod_sec_img_cntrl heigProd p-2" alt="" />
                    </div>
                    <div className="col-12">
                      <p className="p-2 toBold">
                      21 Series Relays & Sockets
                      </p>
                    </div>
                  </div>
                </div>
                
                <div className="col-12 col-md-3 col-lg-3">
                  <div className="row">
                    <div className="col-12">
                      <img src="./assets/images/prod/cc/remote/66-67-Series-Remote-Controler.png" className="img-fluid prod_sec_img_cntrl heigProd p-2" alt="" />
                    </div>
                    <div className="col-12">
                      <p className="p-2 toBold">
                      66 & 67 Series Single & Dual Speed Wireless Remote controllers
                      </p>
                    </div>
                  </div>
                </div>


              </div>

              <div className="row">
            
                <div class="collapse" id="collapseExample">
                  <div className="row d-flex justify-content-around">

                    <div className="col-12 col-md-3 col-lg-3">
                      <div className="row">
                        <div className="col-12">
                          <img src="./assets/images/prod/cr/mosaic/90-Series-Nylon-Grid-Mosaic-01.png" className="img-fluid prod_sec_img_cntrl heigProd p-2" alt="" />
                        </div>
                        <div className="col-12">
                          <p className="p-2 toBold">
                          90 Series Mosaic Mimic Control Panel
                          </p>
                        </div>
                      </div>
                    </div>
                    
                    <div className="col-12 col-md-3 col-lg-3">
                      <div className="row">
                        <div className="col-12">
                          <img src="./assets/images/prod/ip/teminal/77-Series-DIN-Rail-Terminal-Blocks.png" className="img-fluid prod_sec_img_cntrl heigProd p-2" alt="" />
                        </div>
                        <div className="col-12">
                          <p className="p-2 toBold">
                          77 Series DIN Rail Terminal Blocks
                          </p>
                        </div>
                      </div>
                    </div>
                    
                    <div className="col-12 col-md-3 col-lg-3">
                      <div className="row">
                        <div className="col-12">
                          <img src="./assets/images/prod/ip/relayinterface/WIN-Series-Interface-Relay-Modules.png" className="img-fluid prod_sec_img_cntrl heigProd p-2" alt="" />
                        </div>
                        <div className="col-12">
                          <p className="p-2 toBold">
                          WIN Series Interface Relay Modules
                          </p>
                        </div>
                      </div>
                    </div>


                  </div>
                </div>
              </div>

              <p className="mt-4">
                <a class="btn btn_cust_more" data-bs-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
                  More Products
                </a>
              </p>

            </div>
          </div>
        </section>





          {/* <!-- SOLUTIONS BY SECTOR --> */}
          <div class="container-fluid">
            <div class="container-sm pt-5">
              <h2 class="text-center px38n poppins py-5 wow def_ani animate__animated animate__fadeInDown">Solutions By Sector</h2>
              <h6 class="text-center px18n muli pb-5 text_grey">Different sectors have completely different requirements…</h6>
              <div class="row  d-flex align-items-center justify-content-around">

                <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4 py-4 d-flex justify-content-center align-items-center wow def_ani animate__animated animate__fadeIn">
                  <div class="content">
                    <Link to="/robotics" target="">
                      <div class="content-overlay"></div>
                      <img class="content-image" src="./assets/images/robotics-1.png" />
                      <div class="content-details fadeIn-top">
                        <p class="img_solutions_p px22 poppins py-2">ROBOTICS</p>
                      </div>
                    </Link>
                  </div>
                </div>
                <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4 py-4 d-flex justify-content-center align-items-center wow def_ani animate__animated animate__fadeIn">
                  <div class="content">
                    <Link to="/automotive" target="">
                      <div class="content-overlay"></div>
                      <img class="content-image" src="./assets/images/Automotive.png" />
                      <div class="content-details fadeIn-top">
                        <p class="img_solutions_p px22 poppins py-2">AUTOMOTIVE</p>
                      </div>
                    </Link>
                  </div>
                </div>
                <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4 py-4 d-flex justify-content-center align-items-center wow def_ani animate__animated animate__fadeIn">
                  <div class="content">
                    <Link to="/food_beverage" target="">
                      <div class="content-overlay"></div>
                      <img class="content-image" src="./assets/images/food-industry.png" />
                      <div class="content-details fadeIn-top">
                        <p class="img_solutions_p px22 poppins py-2">FOOD &amp; BEVERAGE</p>
                      </div>
                    </Link>
                  </div>
                </div>
                <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4 pt-3 d-flex justify-content-center align-items-center wow def_ani animate__animated animate__fadeIn">
                  <div class="content">
                    <Link to="/" target="">
                      <div class="content-overlay"></div>
                      <img class="content-image" src="./assets/images/agriculture.png" />
                      <div class="content-details fadeIn-top">
                        <p class="img_solutions_p px22 poppins py-2">AGRICULTURE</p>
                      </div>
                    </Link>
                  </div>
                </div>
                <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4 pt-3 d-flex justify-content-center align-items-center wow def_ani animate__animated animate__fadeIn">
                  <div class="content">
                    <Link to="/storage_logistics" target="">
                      <div class="content-overlay"></div>
                      <img class="content-image" src="./assets/images/logistic.png" />
                      <div class="content-details fadeIn-top">
                        <p class="img_solutions_p px22 poppins py-2">STORAGE &amp; LOGISTICS</p>
                      </div>
                    </Link>
                  </div>
                </div>
                <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4 pt-3 d-flex justify-content-center align-items-center wow def_ani animate__animated animate__fadeIn">
                  <div class="content">
                    <Link to="/machineTool" target="">
                      <div class="content-overlay"></div>
                      <img class="content-image" src="./assets/images/machine-tool.png" />
                      <div class="content-details fadeIn-top">
                        <p class="img_solutions_p px22 poppins py-2">MACHINE TOOLS</p>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>



          {/* <!-- Our Partners --> */}
          <div class="container-fluid bg_white_grey">
            <div class="partners_headline d-flex align-items-center justify-content-center">
              <p class="px32 poppins">
                <strong>Our Partners</strong>
              </p>
            </div>
          </div>

          {/* Our partners logo */}
          <div class="container-fluid">
            <div class="container px-2 px-md-5">

              <div class="row mt-5 mb-5">
                <div class="col-12  col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 d-flex justify-content-center align-items-center py-4 py-sm-2">
                  <div class=" d-flex justify-content-center align-items-center">
                    <img src="./assets/images/wernerlogo.png" class="partners_logo1 img-fluid wow def_ani animate__animated animate__fadeInLeft" alt="" />
                  </div>

                </div>
                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 d-flex justify-content-center align-items-center py-4 py-sm-2">
                  <a href="https://www.murrelektronik.com/" target="_blank" class=" d-flex justify-content-center align-items-center">
                    <img src="./assets/images/Home_carousel/murr_green_logo_edit.png" class="partners_logo img-fluid wow def_ani animate__animated animate__fadeInRight" alt="" />
                  </a>
                </div>
              </div>

            </div>

          </div>





          {/* <!-- GET FREE QUOTE --> */}
          <div class="container-fluid bg_get_quote">
            <div class="container">


              <div class="row text_black px-3 pb-0 pb-md-5 ">
                <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 pb-2 pb-lg-5">
                  <h2 class="px26 poppins py-4 wow def_ani animate__animated animate__fadeInLeft">Get Free Quote</h2>
                  <p class="px15 muli text_grey">Drop in your contact details and get a free consultation from our experts. You can talk to them about your industry automation  needs and get a competitive quote.</p>
                  <img src="./assets/images/contact_home.jpeg" class="img-fluid py-0 py-lg-3  pb-0 pb-lg-5 mb-0 mb-md-5 wow def_ani animate__animated animate__fadeIn" alt="" />


                </div>
                <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 pt-0 pt-sm-3">
                  <div class="row pt-1 pt-lg-5 mt-1 mt-lg-5">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 colxl-12 col-xxl-12 pt-0 pt-lg-5 px-3 px-lg-5">
                      <div class="row">
                        <div class="col-12 col-sm-12 col-md-6 mb-3 pt-1">
                          <input type="name" class="form-control py-3" placeholder="Name" aria-label="Name" id="user_name" value={name} onChange={handleChange} />
                          <span>{errorObj.name && <span style={{ color: 'red' }}>{errorObj.name}</span>}</span>
                        </div>
                        <div class="col-12 col-sm-12 col-md-6 mb-3 pt-1">
                          <input type="email" class="form-control py-3" placeholder="E-mail" id="email_id" value={email} onChange={(e) => setEmail(e.target.value)} />
                          <span>{errorObj.email && <span style={{ color: 'red' }}>{errorObj.email}</span>}</span>
                        </div>
                        <div class="col-12 col-sm-12 col-md-6 mb-3 pt-1">
                          <input type="number" class="form-control py-3" placeholder="Phone Number" aria-label="Phone Number" id="phone_number" value={mobile} onChange={(e) => setMobile(e.target.value)} />
                          <span>{errorObj.mobile && <span style={{ color: 'red' }}>{errorObj.mobile}</span>}</span>
                        </div>
                        <div class="col-12 col-sm-12 col-md-6 mb-3 pt-1">
                          <input type="text" class="form-control py-3" placeholder="Country" aria-label="Name" id="country" value={country} onChange={(e) => setCountry(e.target.value)} />
                          <span>{errorObj.country && <span style={{ color: 'red' }}>{errorObj.country}</span>}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 colxl-12 col-xxl-12 pt-3 px-3 px-lg-5">
                      <textarea class="form-control" id="exampleFormControlTextarea1" rows="6" placeholder="Your Message Here" value={message} onChange={(e) => setMessage(e.target.value)}></textarea>
                      <span>{errorObj.message && <span style={{ color: 'red' }}>{errorObj.message}</span>}</span>
                    </div>
                    <div class="col-12 col-sm-12 mb-4">
                      <div class="col-12 mt-4 mt-md-5 pt-2 pt-md-3 ">
                        <button type="button" class="btn submit_button btn-md" onClick={btn}>
                          <span class="text-white px16 muli" onClick={btn}>Submit</span>
                        </button>
                      </div>
                    </div>



                  </div>
                </div>



              </div>
            </div>
          </div>







        </div>
        <Footer/>
      </div>}

    </div>
     
    </div>
   

  )
 
}

export default Home