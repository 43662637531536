import React from 'react'
import Navbar from '../../Components/Navbar'
import { Link } from "react-router-dom";
import Footer from '../../Components/Footer';

function SignalTower() {
  return (
    <>
    <Navbar/>
      <div className=' Interfacebackground text-white'>
        <div className='container System pt-5 mt-3  wow def_ani animate__animated animate__fadeInRight'>
        <h1 className='container System text-white'>Signal Tower Lights</h1>
          <Link className='container System' to='/'>
            <span class="text-white">Home</span></Link>
          <span class="text-white">/</span>
          <a className='container System'>
            <span class="text-white">product</span></a>
          <span class="text-white">/</span>
          <a className='container System'>
            <span class="text-white">Control Components</span></a>
        </div>
      </div>

    
      <div className="container">
        <div className="row mb-5">

            <div className="col-12 col-md-4 col-lg-4">
                <div className="row">
                    <div className="col-12 mb-4">
                    <img src="./assets/images/prod/cc/signal/STACK-LIGHT-ALL3.png" className="img_control_prod img-fluid container-img-top wow def_ani animate__animated animate__fadeIn" alt="" />
                    </div>
                    <div className="col-12">
                    <h4 className="container-sh4 wow def_ani animate__animated animate__fadeInDown">

                    31 Series Stack Lights / Signal Tower Lights                        </h4>
                    <p className='sp wow def_ani_1 animate__animated animate__fadeIn'>
                    •	Completely modular, easy to mount, classy designs. <br/>
                        •	Available five lens colors, Red, yellow, green, blue & white. <br/>
                        •	Available in black & grey housing, 4 different mounting styles. <br/>
                        •	Built-in buzzer module, Piezo Buzzer, Configurable up to 5 tiers. <br/>
                        •	Illumination include incandescent, LED, strobe & spl. flashing LED. <br/>
                        •	Stylish elliptical body, unique lens shape, 360° visual appearance. <br/>
                        •	Light modules in 12Volts & 24Volts AC/DC, 120Volts & 240Volts AC. <br/>

                    </p>
                    </div>
                </div>
            </div>

        </div>
    </div>








     <Footer/>


    </>
  )
}

export default SignalTower 