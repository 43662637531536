import React, { useState } from 'react'
import { CData } from '../Data/ConnectionTechnolgyData';
import Navbar from '../../Components/Navbar'
import { Link } from 'react-router-dom';
import Footer from '../../Components/Footer';

const Connection_Technology = () => {
  const[valuaConnection,setvaluaConnection]=useState([CData]);

  return (
    <div>
      <Navbar/>
      <div className='connectionbackground text-white'>
        <div className='container System pt-5 mt-3 wow def_ani animate__animated animate__fadeInUp'>
        <h1 className='container System text-white'>Connection Technology</h1>
          <Link className='container System' to='/'>
            <span class="text-white">Home</span></Link>
          <span class="text-white">/</span>
          <a className='container System'>
            <span class="text-white">product</span></a>
          <span class="text-white">/</span>
          <a className='container System'>
            <span class="text-white">Connection Technology</span></a>
        </div>
      </div>

   <div className='container' style={{marginTop:'20px'}}>
        <div className="row vadjust2">
          {
            CData.map((val) => {
              return (
                <div className='s_box cardSize'>
                  <div className="col-lg-4 col-sm-12 col-md-4">
                    <div className='card-image-size' >
                      <div className='imgage_height_system'>
                      <img src={val.image} className="container-img-top wow def_ani animate__animated animate__fadeIn" alt="container" />
                      </div>
                      <div className="container-sb" >
                        <h4 className="container-sh4 wow def_ani animate__animated animate__fadeInDown">{val.name}</h4>
                        <p className='sp wow def_ani_1 animate__animated animate__fadeIn'>{val.description}</p>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
        </div>
      </div>
      <Footer/>
   </div>
  )
}

export default Connection_Technology