import React from 'react'
import Navbar from '../../Components/Navbar'
import { Link } from "react-router-dom";
import Footer from '../../Components/Footer';

function RelaysAndSockets() {
  return (
    <>
    <Navbar/>
      <div className=' Interfacebackground text-white'>
        <div className='container System pt-5 mt-3  wow def_ani animate__animated animate__fadeInRight'>
        <h1 className='container System text-white'>Relays And Sockets</h1>
          <Link className='container System' to='/'>
            <span class="text-white">Home</span></Link>
          <span class="text-white">/</span>
          <a className='container System'>
            <span class="text-white">product</span></a>
          <span class="text-white">/</span>
          <a className='container System'>
            <span class="text-white">Interface</span></a>
        </div>
      </div>

      <div className='container mb-5' style={{marginTop:'20px'}}>
        <div className="row vadjust">

        <div className='s_box cardSize  mt-5 mb-5'>

            <div className="col-lg-4 col-sm-12 col-md-4">
                <div className='card-image-size'>
                    <div className='imgage_height_system'>
                        <img src="./assets/images/prod/cc/relays/21-series-relay.png" className="img-fluid container-img-top wow def_ani animate__animated animate__fadeIn" alt="" />
                    </div>
                    <div className="container-sb">
                    <h4 className="container-sh4 wow def_ani animate__animated animate__fadeInDown">
                    21 Series Relays & Sockets
                    </h4>
                    <p className='sp wow def_ani_1 animate__animated animate__fadeIn'>
                    •	Contacts: DPDT / 4PDT <br/>
•	Built in options include LED, Check Button, Latch Button, Mechanical Flag Indicator <br/>
•	Current Rating: 10A @ 220VAC for DPDT and 6A @ 220VAC for 4PDT relays <br/>
•	No soldering inside the relays. All are welded spots. <br/>
•	Standard foot print, hence can be used on any socket <br/>
•	Sockets range include IP 20 finger protection type and PCB terminals <br/>

                    </p>
                    </div>
                </div>
            </div>

        </div>
        
        <div className='s_box cardSize  mt-5 mb-5'>

            <div className="col-lg-4 col-sm-12 col-md-4">
                <div className='card-image-size'>
                    <div className='imgage_height_system'>
                        <img src="./assets/images/prod/cc/relays/22-series-relay.png" className="img-fluid container-img-top wow def_ani animate__animated animate__fadeIn" alt="" />
                    </div>
                    <div className="container-sb">
                    <h4 className="container-sh4 wow def_ani animate__animated animate__fadeInDown">
                    22 Series Relays & Sockets
                    </h4>
                    <p className='sp wow def_ani_1 animate__animated animate__fadeIn'>
                    •	Available in SPDT and DPDT. <br />
•	Maximum function minimum space design. <br />
•	Suitable for 50 Hz and 60 Hz frequency cycles. <br />
•	Available in 6, 12, 24, 110, 220 & 240V AC and DC. <br />
•	Built in Diode models with contact capacity of 10 Amperes. <br />

                    </p>
                    </div>
                </div>
            </div>

        </div>
     
        <div className='s_box cardSize  mt-5 mb-5'>

            <div className="col-lg-4 col-sm-12 col-md-4">
                <div className='card-image-size'>
                    <div className='imgage_height_system'>
                        <img src="./assets/images/prod/cc/relays/23-series-relay.png" className="img-fluid container-img-top wow def_ani animate__animated animate__fadeIn" alt="" />
                    </div>
                    <div className="container-sb">
                    <h4 className="container-sh4 wow def_ani animate__animated animate__fadeInDown">
                    23 Series Relays & Sockets
                    </h4>
                    <p className='sp wow def_ani_1 animate__animated animate__fadeIn'>
                    •	Available in DPDT and 3PDT <br />
•	Suitable for 50 Hz and 60 Hz frequency cycles <br />
•	Coil status and contact status indication built in <br />
•	Available in 6, 12, 24, 110, 220 & 240V AC and DC <br />
•	Suitable for Heavy duty or even vibrating environments <br />
•	Can take up to 10 Ampere Continuous Load Current <br />
•	Fixed contacts powered by WERNER AFT (Anti-Fuse-Technology) <br />

                    </p>
                    </div>
                </div>
            </div>

        </div>
        </div>

        <div className="row vadjust">
        <div className='s_box cardSize  mt-5 mb-5'>

            <div className="col-lg-4 col-sm-12 col-md-4">
                <div className='card-image-size'>
                    <div className='imgage_height_system'>
                        <img src="./assets/images/prod/cc/relays/24-series-re.png" className="img-fluid container-img-top wow def_ani animate__animated animate__fadeIn" alt="" />
                    </div>
                    <div className="container-sb">
                    <h4 className="container-sh4 wow def_ani animate__animated animate__fadeInDown">
                    24 Series Relays & Sockets
                    </h4>
                    <p className='sp wow def_ani_1 animate__animated animate__fadeIn'>
                    •	All Relays are fully sealed type. <br />
•	Contact capacity is 6 Amps@230VAC <br />
•	Available standard voltage ratings from 6 to 48V AC/DC. <br />
•	Designed for direct PC or panel mount via 74 Series sockets. <br />
•	Featuring only 5 mm width with both relay and socket put together <br />
•	More than 100,000 operational life <br />

                    </p>
                    </div>
                </div>
            </div>

        </div>
        
        <div className='s_box cardSize  mt-5 mb-5'>

            <div className="col-lg-4 col-sm-12 col-md-4">
                <div className='card-image-size'>
                    <div className='imgage_height_system'>
                        <img src="./assets/images/prod/cc/relays/26-series.png" className="img-fluid container-img-top wow def_ani animate__animated animate__fadeIn" alt="" />
                    </div>
                    <div className="container-sb">
                    <h4 className="container-sh4 wow def_ani animate__animated animate__fadeInDown">
                    26 Series Relays & Switches
                    </h4>
                    <p className='sp wow def_ani_1 animate__animated animate__fadeIn'>
                    •	Dielectric strength <br />
•	Available as SPDT, DPDT, SPST-NO & DPST-NO. <br />
•	Available in 8 Amps and 12 Amps contact capacity. <br />
•	Compact and small with only 16 millimeters height. <br />
•	Standard voltage ratings 6V to 240V AC & 6V to 110V DC. <br />
•	Can be directly mounted on PC Board, PCB socket or Panel mountable socket <br />

                    </p>
                    </div>
                </div>
            </div>

        </div>
     
        <div className='s_box cardSize  mt-5 mb-5'>

            <div className="col-lg-4 col-sm-12 col-md-4">
                <div className='card-image-size'>
                    <div className='imgage_height_system'>
                        <img src="./assets/images/prod/cc/relays/28-29-series.png" className="img-fluid container-img-top wow def_ani animate__animated animate__fadeIn" alt="" />
                    </div>
                    <div className="container-sb">
                    <h4 className="container-sh4 wow def_ani animate__animated animate__fadeInDown">
                    28/29 Series Solid State Relays
                    </h4>
                    <p className='sp wow def_ani_1 animate__animated animate__fadeIn'>
                    •	Solid state relays of fully sealed type <br />
•	Ranging from 2 Amps to 100 Amperes <br />
•	Available in both AC and DC control voltages <br />
•	Suitable for 50 Hz and 60 Hz frequency cycles <br />
•	Operation indicator enables monitoring operation <br />
•	Built-in snubber circuit for external surges with high efficiency. <br />
•	Unique heat sink to save space and maximum heat dissipation <br />

                    </p>
                    </div>
                </div>
            </div>

        </div>
        </div>

        <div className="row vadjust">

        <div className='s_box cardSize  mt-5 mb-5'>

            <div className="col-lg-4 col-sm-12 col-md-4">
                <div className='card-image-size'>
                    <div className='imgage_height_system'>
                        <img src="./assets/images/prod/cc/relays/290-Series-PCB-Relay.png" className="img-fluid container-img-top wow def_ani animate__animated animate__fadeIn" alt="" />
                    </div>
                    <div className="container-sb">
                    <h4 className="container-sh4 wow def_ani animate__animated animate__fadeInDown">
                    290 Series PCB Power Relays
                    </h4>
                    <p className='sp wow def_ani_1 animate__animated animate__fadeIn'>
                    •	30A Switching Capability <br />
•	4.5KV Dielectric strength <br />
•	Class F Insulation available <br />
•	Open type and sealed type available <br />
•	Environmental friendly(RoHS complaint) <br />
•	Outline Dimentions (32.4 x 27.5 x 20)mm <br />
•	Current Rating: 30A 240VAC for 1A, 20A 240VAC for 1B, 30A 240VAC for 1C(NO), 20A 240VAC for 1C(NC) <br />

                    </p>
                    </div>
                </div>
            </div>

        </div>

        <div className='s_box cardSize  mt-5 mb-5'>

            <div className="col-lg-4 col-sm-12 col-md-4">
                <div className='card-image-size'>
                    <div className='imgage_height_system'>
                        <img src="./assets/images/prod/cc/relays/70-75-Series-Sockets-.png" className="img-fluid container-img-top wow def_ani animate__animated animate__fadeIn" alt="" />
                    </div>
                    <div className="container-sb">
                    <h4 className="container-sh4 wow def_ani animate__animated animate__fadeInDown">
                    70 – 75 Series Sockets
                    </h4>
                    <p className='sp wow def_ani_1 animate__animated animate__fadeIn'>
                    •	Sockets with built in diodes. <br />
•	Sockets dielectric strength 2000V. <br />
•	Sockets with 10 amps contact ratings. <br />
•	Options from single pole up to four poles. <br />
•	Suitable for Din rail and PC board mounting. <br />
•	Sockets with screw type & plug in type termination. <br />
•	Werner sockets can accommodate all makes of relays. <br />

                    </p>
                    </div>
                </div>
            </div>

        </div>
        </div>

        </div>

     <Footer/>


    </>
  )
}

export default RelaysAndSockets