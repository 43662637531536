import React from 'react'
import Navbar from '../../Components/Navbar'
import { Link } from "react-router-dom";
import Footer from '../../Components/Footer';

function FanFailureDetector() {
  return (
    <>
    <Navbar/>
      <div className=' Interfacebackground text-white'>
        <div className='container System pt-5 mt-3  wow def_ani animate__animated animate__fadeInRight'>
        <h1 className='container System text-white'>Fan Failure Detector </h1>
          <Link className='container System' to='/'>
            <span class="text-white">Home</span></Link>
          <span class="text-white">/</span>
          <a className='container System'>
            <span class="text-white">product</span></a>
          <span class="text-white">/</span>
          <a className='container System'>
            <span class="text-white">Fan Failure Detector </span></a>
        </div>
      </div>

      <div className="container">
        <div className="row mb-5">

            <div className="col-12 col-md-4 col-lg-4">
                <div className="row">
                    <div className="col-12 mb-4">
                    <img src="./assets/images/prod/other/FFD-image-1.png" className="img_control_prod img-fluid container-img-top wow def_ani animate__animated animate__fadeIn" alt="" />
                    </div>
                    <div className="col-12">
                    <h4 className="container-sh4 wow def_ani animate__animated animate__fadeInDown">
                    68.01 Series Fan Failure Detector FFD

                    </h4>
                    <p className='sp wow def_ani_1 animate__animated animate__fadeIn'>
                    •	Measures ambient temperature. <br/>
•	Seven Segment displays to indicate ambient temperature. <br/>
•	Measures FAN parameters in RUN/STALL condition. <br/>
•	Programmable FAN voltage of 230V / 110V. <br/>
•	Facility to characterize individual fan motors. <br/>
•	Saves power and heating of Fan motors. <br/>


                    </p>
                    </div>
                </div>
            </div>
            
          
        </div>
    </div>




     <Footer/>


    </>
  )
}

export default FanFailureDetector 