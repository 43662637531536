import React from 'react'
import Navbar from '../../Components/Navbar'
import { Link } from "react-router-dom";
import Footer from '../../Components/Footer';

function Annunciation() {
  return (
    <>
    <Navbar/>
      <div className=' Interfacebackground text-white'>
        <div className='container System pt-5 mt-3  wow def_ani animate__animated animate__fadeInRight'>
        <h1 className='container System text-white'>Annunciation Windows</h1>
          <Link className='container System' to='/'>
            <span class="text-white">Home</span></Link>
          <span class="text-white">/</span>
          <a className='container System'>
            <span class="text-white">product</span></a>
          <span class="text-white">/</span>
          <a className='container System'>
            <span class="text-white">Control Components</span></a>
        </div>
      </div>

    
      <div className="container">
        <div className="row mb-5">

            <div className="col-12 col-md-4 col-lg-4">
                <div className="row">
                    <div className="col-12 mb-4">
                    <img src="./assets/images/prod/cc/annucitaion/30-Series-Annuciator-Windows.png" className="img_control_prod img-fluid container-img-top wow def_ani animate__animated animate__fadeIn" alt="" />
                    </div>
                    <div className="col-12">
                    <h4 className="container-sh4 wow def_ani animate__animated animate__fadeInDown">
                    30 Series – Annunciator Windows
                        </h4>
                        <p className='sp wow def_ani_1 animate__animated animate__fadeIn'>
                        •	Bright and colorful display surface <br/>
                        •	Configurable up to a maximum of 200 windows <br/>
                        •	Built in three-phase flasher, buzzer amplifier circuit <br/>
                        •	Windows sizes in 30×30, 30×60, 60×30 and 60×60 mm <br/>
                        •	Different windows sizes can be incorporated in a single unit <br/>
                        •	Lens colors include Red, Amber, Yellow, Green, Blue & White <br/>
                        •	Multi-chip LEDs available in 6V, 12V, 24V, 110V & 220V AC/DC <br/>


                        </p>
                    </div>
                </div>
            </div>
            
            <div className="col-12 col-md-4 col-lg-4">
                <div className="row">
                    <div className="col-12 mb-4">
                    <img src="./assets/images/prod/cc/annucitaion/68-Series-Annunciator-Logic-Controllers-1.png" className="img_control_prod img-fluid container-img-top wow def_ani animate__animated animate__fadeIn" alt="" />
                    </div>
                    <div className="col-12">
                    <h4 className="container-sh4 wow def_ani animate__animated animate__fadeInDown">
                    68 Series Annunciator Logic Controller
                        </h4>
                        <p className='sp wow def_ani_1 animate__animated animate__fadeIn'>
                        •	FIFO, LIFO, Ringback and 15 other ISA / JIS sequences built in <br/>
                        •	Sequences can be selected by dip switches on field <br/>
                        •	Input NO / NC status can be set by dip switches on field <br/>
                        •	Basic module available with 16 I/P & 16 O/P <br/>
                        •	I/Os expandable to control upto 200 windows <br/>



                        </p>
                    </div>
                </div>
            </div>

        </div>
    </div>




     <Footer/>


    </>
  )
}

export default Annunciation 