export const IData = [
    {
        id:1,
        image: 'Images/InterfaceImage/1-1.jpg',
        name: 'Lighting Elements',
        description: 'Murrelektronik’s lighting systems use the best technology on the market. The LEDs are made to last and don’t need much power. The Modlight50 Pro and Modlight70 Pro signal tower’s are easy to assemble – the innovative mounting system makes the different colored lights easy to interchange.'
    },
    {
        id:2,
        image: 'Images/InterfaceImage/2-1.jpg',
        name: 'Modlink Heavy',
        description: 'The heavy-duty plug connectors of the Modlink Heavy series from Murrelektronik guarantee secure transmission of signals, energy, data and pneumatics – even in the roughest of industrial environments. They consistently protect interfaces from water, coolants and lubricants, dirt and high mechanical loads.'
    },
    {
        id:3,
        image: 'Images/InterfaceImage/3-1.jpg',
        name: 'Modlink MPV',
        description: 'Connecting fieldbus systems to cabinets is important for day-to-day maintenance. But the interface has to be durable to ensure reliable data transfer. Modlink MPV cable entry systems make it possible to use pre-wired cordsets to reduce setup time and speed up the maintenance process.'
    },
    {
        id:4,
        image: 'Images/InterfaceImage/5-1.jpg',
        name: 'Modlink MSVD',
        description: 'Applications in the control cabinet require the right power outlets. Murrelektronik’s wide range includes many versions for global applications.'
    },
    {
        id:5,
        image: 'Images/InterfaceImage/4-1.jpg',
        name: 'Modlink® MSDD',
        description: 'Modlink MSDD interfaces provide convenient access to the controls when diagnostics or service work is necessary. The cabinet door stays closed so your machine or installation can maintain the recommended protection class.'
    },
    {
        id:6,
        image: 'Images/InterfaceImage/6-1.jpg',
        name: 'Modlink Vario',
        description: 'Modlink Vario is a modular media connector to couple and decouple pneumatics, fluids and electronics with just one system. It is suitable for uncomplicated use on switch cabinets, tools and machine parts.'
    },
    {
        id:7,
        image: 'Images/InterfaceImage/7-1.jpg',
        name: 'MSDD Hybrid Fieldbus Coupling',
        description: 'MSDD hybrid fieldbus couplers transfer data and power from the cabinet into the field. A repeater function amplifies and processes signals.'
    }
]